/*eslint-disable*/
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

// @material-ui/core components
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'

// core components
import { dict } from '@components/Translate'
import AdminNavbarLinks from '@components/Navbars/AdminNavbarLinks.js'

import styles from '@js/material/components/sidebarStyle.js'

import './styles.scss'

const useStyles = makeStyles(styles)

export default function Sidebar(props) {
  const classes = useStyles()

  function activeRoute(routeName, routes) {
    let value = ''
    const pathname = window.location.pathname
    if (routes && routes.length) {
      for (const { layout, path } of routes) {
        value = pathname.includes(`${layout}${path}`)
      }
    }
    return routeName.includes(pathname) || value
  }

  const { color, logo, routes } = props

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const { layout, path, disabled } = prop;

        let listItemClasses = clsx({
          [' ' + classes[color]]: activeRoute(layout + path, prop.routes)
        })

        const whiteFontClasses = clsx({
          [' ' + classes.whiteFont]: activeRoute(
            layout + path, prop.routes
          )
        })

        const classNameLink = clsx([classes.item], { disabled })

        return (
          <NavLink
            key={key}
            activeClassName="active"
            className={classNameLink}
            to={layout + path}
          >
            <ListItem
              button
              className={classes.itemLink + ' ' + listItemClasses}
            >
              {typeof prop.icon === 'string' ? (
                <Icon
                  className={clsx(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                  <prop.icon
                    className={clsx(classes.itemIcon, whiteFontClasses)}
                  />
                )}
              <ListItemText
                disableTypography={true}
                primary={dict.translate(prop.name)}
                className={clsx(classes.itemText, whiteFontClasses)}
              />
            </ListItem>
          </NavLink>
        )
      })}
    </List>
  )

  var brand = (
    <div className={classes.logo}>
      <img className={classes.img} src={logo} />
    </div>
  )

  return (
    <div className="Sidebar">
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={props.open}
          classes={{
            paper: clsx(classes.drawerPaper)
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          <div className={'background_siderbar'} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          open
          anchor={'left'}
          variant="permanent"
          classes={{ paper: clsx(classes.drawerPaper) }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={'background_siderbar'} />
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
}
