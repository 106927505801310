import { createMuiTheme } from '@material-ui/core/styles';

const typography = {
  fontFamily: "'Barlow Regular','Roboto', 'Helvetica', 'Arial', 'sans-serif'",
  fontBold: 'Barlow Bold',
  fontThin: 'Barlow Thin',
  fontBlack: 'Barlow Black',
  fontLight: 'Barlow Light',
  fontItalic: 'Barlow Italic',
  fontMedium: 'Barlow Medium',
  fontRegular: 'Barlow Regular',
  fontSemibold: 'Barlow SemiBold',
  fontExtrabold: 'Barlow ExtraBold',
  fontExtraLight: 'Barlow ExtraLight',
}

const theme = createMuiTheme({
  typography,
  palette: {
    primary: {
      main: "#7f9200",
      dark: "#AAC208"
    },
    secondary: {
      main: "#4D4D4D",
      dark: "#CC9F23"
    },
    white: "#fff",
    grey: {
      150: '#F8F8F8',
      250: '#d9d9d9'
    },
    text: {
      primary: "#000",
      secondary: "#717275",
      white: '#fff',
      black: '#000',
      title: '#4D4D4D',
      subtitle: '#1A1A1A',
      main: '#707070',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-disabled' : {
          color: "#fff",
        }
      }
    },
    MuiDialogContent: {
      root: {
        paddingBottom: 15,
        overflowY: 'initial',
      }
    }
  }
});

export default theme;