import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette }) => ({
  root: {
    marginBottom: '10rem'
  },
  main: {
    margin: '3rem 0',
    '@media print': {
      margin: '0',
    },
  },
  container: {
    margin: 'auto',
    '& .MuiGrid-item': {
      padding: '25px 40px'
    },
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  title: {
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      fontFamily: typography.fontSemibold,
    },
  },
}))
