import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import Translate from "@components/Translate";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import * as Material from "@material-ui/core/";
import styles from "@js/material/components/buttonStyle.js";

import "./styles.scss";

const useStyles = makeStyles(styles);

const Button = (props) => {
  const classes = useStyles();

  const {
    size,
    link,
    round,
    block,
    simple,
    variant,
    children,
    disabled,
    justIcon,
    className,
    muiClasses,
    color = 'primary',
    ...rest
  } = props;

  const btnClasses = clsx(["Button"], {
    [classes.link]: link,
    [classes[size]]: size,
    [classes.round]: round,
    [classes.block]: block,
    [className]: className,
    [classes[color]]: color,
    [classes.simple]: simple,
    [classes.justIcon]: justIcon,
    [classes.disabled]: disabled,
  });

  return (
    <Material.Button {...rest} variant={variant} disabled={disabled} classes={muiClasses} className={btnClasses}>
      <Translate>{props.children}</Translate>
    </Material.Button>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf([
    "info",
    "rose",
    "white",
    "danger",
    "success",
    "warning",
    "primary",
    "secondary",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  link: PropTypes.bool,
  round: PropTypes.bool,
  block: PropTypes.bool,
  simple: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
};

export default Button;
