import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette }) => ({
  root: {
    marginBottom: '10rem'
  },
  title: {
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginBottom: '2rem',
      fontFamily: typography.fontSemibold,
      '@media print': {
        marginBottom: '1rem',
      },
    },
  },
  body: {
    gridGap: 30,
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  item: {
    display: 'flex',
    padding: '0 5px',
    textAlign: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  header: {
    gridGap: 15,
    display: 'grid',
    marginBottom: 10,
    gridAutoRows: 'minmax(80px, auto)',
    gridTemplateColumns: '160px 1fr 1fr 1fr 160px 1fr 160px 1fr 160px',
    '& $item:nth-child(1), $item:nth-child(5), $item:nth-child(7), $item:nth-child(9)': {
      '& p': {
        fontFamily: typography.fontSemibold,
      },
    },
  },
  itemRow: {
    padding: 10,
    display: 'flex',
    fontSize: '1.2rem',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rows: {
    gridGap: 15,
    display: 'grid',
    gridAutoRows: 'minmax(80px, auto)',
    gridTemplateColumns: '160px 1fr 1fr 1fr 160px 1fr 160px 1fr 160px',
    '& $itemRow:nth-child(1)': {
      backgroundColor: "#EFEFEF",
      fontFamily: typography.fontSemibold,
    },
    '& $itemRow:nth-child(2)': {
      backgroundColor: "#F8F8F8"
    },
    '& $itemRow:nth-child(3)': {
      backgroundColor: "#F8F8F8"
    },
    '& $itemRow:nth-child(4)': {
      backgroundColor: "#F7FAE0"
    },
    '& $itemRow:nth-child(5)': {
      backgroundColor: "#F9FFD1",
      fontFamily: typography.fontSemibold,
    },
    '& $itemRow:nth-child(6)': {
      backgroundColor: "#EBFC7C"
    },
    '& $itemRow:nth-child(7)': {
      backgroundColor: "#E3F75D",
      fontFamily: typography.fontSemibold,
    },
    '& $itemRow:nth-child(8)': {
      backgroundColor: "#E2FCB0"
    },
    '& $itemRow:nth-child(9)': {
      backgroundColor: "#D4FA8B",
      fontFamily: typography.fontSemibold,
    },
  }
}))
