import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  column: {
    paddingBottom: '8px !important',
  },
  item_column: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: typography.fontRegular,
    backgroundColor: palette.grey[150],
    '@media print': {
      fontSize: '1rem'
    },
  },
  header: {
    marginBottom: 5,
  },
  item: {
    textAlign: 'center',
  },
  group: {
    paddingBottom: 10,
  },
  root: {
    '& $group:last-child': {
      padding: 0,
    },
  },
}))
