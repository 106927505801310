import React from 'react'

import Typography from '@components/Typography'
import TableResult from '@components/TableResult'

import useStyles from './styles.js'

export default function ResultsByCategory({ result = {}, mack }) {
  const classes = useStyles()
  const { categories = [] } = result
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">RESULT_BY_CATEGORY_TITLE</Typography>
      </div>
      <TableResult array={categories} mack={mack} />
    </div>
  )
}
