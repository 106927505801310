import React from 'react'

import Translate from '@components/Translate'
import ItemGroupInput from '../../../ItemGroupInput'

import './styles.scss'

const FormGroupFooter = ({ item, updateList }) => {
  return (
    <div className="FormGroupFooter">
      <div className="footer-title">
        {/* <h3>
          <Translate>FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_LOGO</Translate>
        </h3> */}
      </div>
      <ItemGroupInput name='logoOne' label='LOGO' values={item} updateList={updateList} />
      <div className="footer-title">
        <h3>
          <Translate>FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_LOGO_TWO</Translate>
        </h3>
      </div>
      <ItemGroupInput name='logoTwo' label='LOGO' values={item} updateList={updateList} />
    </div>
  )
}

export default FormGroupFooter
