import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'
import Axios from '@components/Axios'
import Button from '@components/Button'

import JsonFormData from 'json-form-data'

import './styles.scss'

const ItemGroupInput = ({ name, label, values, updateList }) => {

  const [data, setData] = useState()

  const onSuccess = () => {
    setData({})
    updateList()
  }

  const onSubmit = ({ submit }) => {
    let options = {
      includeNullValues: false,
      showLeafArrayIndexes: true,
      initialFormData: new FormData()
    }
    let formData = JsonFormData(data, options)
    submit({ formData })
  }

  const handleChange = ({ target: { name, value } }) => {
    const values = {}
    Utils.setValue(values, name, value)
    setData({ ...values })
  }

  const viewFile = (url) => {
    if (typeof url === 'string') {
      window.open(url, { target: '_black' })
    }
  }

  return (
    <div className="ItemGroupInput">
      <Axios
        method="put"
        api="admin.rodape"
        onSuccess={onSuccess}
        headers={{ 'Content-type': "multipart/form-data;boundary='cecarbon'" }}
      >
        {({ submit }) => (
          <Grid container>
            <Grid item xs={2} sm={2}>
              <Input
                type="file"
                name={name}
                value={values[name]}
                onChange={handleChange}
                label={`LOGO`}
              />
            </Grid>
            <Grid item xs={2} sm={2} className="item-group-input-item">
              <Button disabled={!data} onClick={() => onSubmit({ submit })} color="secondary" className="footer-button">FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_BUTTON_CHANGE</Button>
              <Button disabled={!!data} onClick={() => viewFile(values[name])} color="secondary" className="footer-button">FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_BUTTON_VIEW</Button>
            </Grid>
          </Grid>
        )}
      </Axios>
    </div>
  )
}

export default ItemGroupInput
