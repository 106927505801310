import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import Table from '@components/Table'
import Icon from '@components/Icon'
import Button from '@components/Button'

import './styles.scss'
import ManagerAddEdit from './components/ManagerAddEdit'
import DeleteManager from './components/DeleteManager'

const ManagersTableList = ({ array = [], updateList, creatorId }) => {
  const [managerToEditId, setManagerToEditId] = React.useState(null)
  const [managerToRemoveId, setManagerToRemoveId] = React.useState(null)
  const [addingManager, setAddingManager] = React.useState(false)
  // const

  const columns = [
    { title: 'CLIENTS_VIEW_MANAGERS_LIST_NAME_TITLE', field: 'user.name' },
    {
      title: 'CLIENTS_VIEW_MANAGERS_LIST_POSITION_TITLE',
      field: 'position'
    },
    {
      title: 'CLIENTS_VIEW_MANAGERS_LIST_CONSTRUCTIONS_NUMBER',
      className: 'constructions',
      component: item => {
        return <div>{item.constructions.length}</div>
      }
    },
    {
      title: 'CLIENTS_VIEW_MANAGERS_LIST_PHONE_TITLE',
      field: 'phone'
    },
    {
      title: 'CLIENTS_VIEW_MANAGERS_LIST_REGISTRATION_TYPE_TITLE',
      field: 'registrationType'
    },
    {
      title: 'CLIENTS_VIEW_MANAGERS_LIST_REGISTRATION_NUMBER_TITLE',
      field: 'registrationNumber'
    },
    {
      title: 'CLIENTS_VIEW_LIST_OPTIONS',
      className: 'options',
      component: ({ id }) => {
        return (
          <>
            <IconButton
              onClick={() => setManagerToEditId(id)}
              className="list-edit"
            >
              <Icon size={30} name="edit" />
            </IconButton>
            <IconButton
              onClick={() => setManagerToRemoveId(id)}
              className="list-delete"
            >
              <Icon size={30} name="delete_icon" />
            </IconButton>
          </>
        )
      }
    }
  ]

  const handleClose = () => {
    setManagerToEditId(null)
    setAddingManager(false)
  }

  const component = () => {
    return (
      <div className="add-manager" onClick={() => setAddingManager(true)}>
        <Button color="success">CLIENTS_VIEW_ADD_MANAGER</Button>
      </div>
    )
  }

  return (
    <div className="TableList">
      <Table
        data={array}
        columns={columns}
        component={component}
        title="CLIENTS_VIEW_MANAGERS_LIST_TABLE_TITLE"
      />
      <ManagerAddEdit
        isOpen={!!managerToEditId || addingManager}
        creatorId={creatorId}
        onClose={handleClose}
        item={array.find(({ id }) => id === managerToEditId)}
        updateList={updateList}
      />
      <DeleteManager
        isOpen={!!managerToRemoveId}
        onClose={() => setManagerToRemoveId(null)}
        manager={array.find(({ id }) => id === managerToRemoveId)}
        updateList={updateList}
      />
    </div>
  )
}

export default ManagersTableList

ManagersTableList.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
