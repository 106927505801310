import React from 'react'

import { TwitterPicker } from 'react-color'

import TextField from '@material-ui/core/TextField'

// Styles
import './styles.scss'

const Color = props => {
  const colors = [
    '#FFC312',
    '#C4E538',
    '#12CBC4',
    '#12CBC4',
    '#FDA7DF',
    '#ED4C67',
    '#F79F1F',
    '#A3CB38',
    '#1289A7',
    '#D980FA',
    '#B53471',
    '#EE5A24',
    '#009432',
    '#0652DD',
    '#9980FA',
    '#833471',
    '#006266',
    '#1B1464',
    '#5758BB',
    '#6F1E51',
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#EB144C',
    '#F78DA7',
    '#9900EF',
    '#84817a',
    '#cc8e35'
  ]

  const handleChange = ({ hex }) => {
    const { name } = props
    const event = {
      target: {
        name,
        value: hex
      }
    }
    props.handleChange(event)
  }

  let { value } = props

  return (
    <div className="ColorPicker">
      <TextField {...props} type='text' />
      <TwitterPicker
        color={value}
        {...props}
        colors={colors}
        onChange={handleChange}
      />
    </div>
  )
}

export default Color
