import React from 'react'
import PropTypes from 'prop-types'

import Table from '@components/Table'

import './style.scss'

const List = ({ list = [], setItem, ...props }) => {

  const columns = [
    { title: 'ENTERPRISETYPE_LIST_NAME', field: 'name' }
  ]

  return (
    <div className="List">
      <Table {...props} data={list} columns={columns} title="ENTERPRISETYPES_LIST_TABLE_TITLE" />
    </div>
  )
}

export default List

List.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
