import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette }) => ({
  root: {
    paddingBottom: '10rem',
  },
  title: {
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginBottom: '2rem',
      fontFamily: typography.fontSemibold,
    },
  },
  item: {
    textAlign: 'center',
  },
  column: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.grey[150],
  },
}))
