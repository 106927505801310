import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Info from "@material-ui/icons/Info";
// core components

import Utils from '@utils'
import Icon from "@components/Icon";
import Card from "@components/CardFull";
import Translate from "@components/Translate";
import CardIcon from "@components/CardFull/CardIcon";
import CardHeader from "@components/CardFull/CardHeader";
import CardFooter from "@components/CardFull/CardFooter";

import styles from "@js/material/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function CardView(props) {
  const classes = useStyles();
  const { color, icon, value, title, description } = props; 

  const isNumber = (value) => {
    return Utils.numberFormart(value, 0)
  }

  return (
    <div className="CardView">
      <Card>
        <CardHeader color={color} stats icon>
          <CardIcon color={color}>
            <Icon size={35} name={icon} />
          </CardIcon>
          <div className={classes.cardBody}>
            <p className={classes.cardCategory}>
              <Translate>{title}</Translate>
            </p>
            <h3 className={classes.cardTitle}>
              {!isNaN(value) ? isNumber(value) : value}
            </h3>
          </div>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <Info />
            <Translate>{description}</Translate>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
