import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'
import Stepper from '@components/Stepper'

import Status from '@constants/status.constansts'

const ConfigComponent = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik

  const status = [
    {
      label: 'Rascunho',
      value: Status.DRAFT
    },
    {
      label: 'Publicado',
      value: Status.PUBLISHED
    }
  ]

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs className="mg-auto">
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_TITLE"
              value={Utils.getValue(values, 'title')}
              error={Utils.getValue(errors, 'title')}
              helperText={Utils.getValue(errors, 'title')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              type="date"
              name="date"
              onBlur={handleBlur}
              onChange={handleChange}
              label="METHODOLOGY_ADD_EDIT_INPUT_LABEL_DATE"
              value={Utils.getValue(values, 'date')}
              error={Utils.getValue(errors, 'date')}
              helperText={Utils.getValue(errors, 'date')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              translate
              type="select"
              name="status"
              array={status}
              nameText="label"
              nameValue="value"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_STATUS"
              value={Utils.getValue(values, 'status')}
              error={Utils.getValue(errors, 'status')}
              helperText={Utils.getValue(errors, 'status')}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} className="mg-auto">
          <Input
            {...props}
            name="archive"
            type="file"
            aspectRatio={6 / 4}
            onBlur={handleBlur}
            onChange={handleChange}
            value={Utils.getValue(values, 'archive')}
            error={Utils.getValue(errors, 'archive')}
            helperText={Utils.getValue(errors, 'archive')}
          />
        </Grid>
      </Grid>
    </>
  )
}

const FormGroup = props => {
  const steps = [
    {
      label: 'VIDEO_STEPS_LABEL_CONFIG',
      component: props => <ConfigComponent {...props} />
    }
  ]

  return <Stepper {...props} steps={steps} />
}

export default FormGroup
