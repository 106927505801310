import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import Axios from '@components/Axios'
import Icon from '@components/Icon'
import Table from '@components/Table'

import './styles.scss'

const PostList = ({ list = [], setItem, updateList }) => {
  const columns = [
    { title: 'CALCULATOR_LIST_MATERIAL_NAME_TITLE', field: 'materialName' },
    { title: 'CALCULATOR_LIST_INPUT_UNIT', field: 'inputUnit' },
    { title: 'CALCULATOR_LIST_OUTPUT_UNIT', field: 'outputUnit' },
    { title: 'CALCULATOR_LIST_CONVERSION_FACTOR', field: 'conversionFactor' },
    {
      title: 'POST_LIST_OPTIONS',
      className: 'options',
      component: item => {
        return (
          <>
            <IconButton onClick={() => setItem(item)} className="list-edit">
              <Icon size={30} name="edit" />
            </IconButton>
            <Axios
              api="calculator"
              method="delete"
              others={item._id}
              onSuccess={updateList}
            >
              {({ submit }) => (
                <IconButton onClick={submit} className="list-delete">
                  <Icon size={30} name="delete_icon" />
                </IconButton>
              )}
            </Axios>
          </>
        )
      }
    }
  ]

  return (
    <div className="PostList">
        
      <Table data={list} columns={columns} title="CALCULATOR_LIST_TABLE_TITLE" />
           
    </div>
  )
}

export default PostList

PostList.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
