import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '&.success': {
      '& .Icon': {
        '& svg': {
          color: palette.primary.main
        },
      },
    },
    '&.error': {
      '& .Icon': {
        '& svg': {
          color: palette.error.dark,
        },
      },
    },
  },
  icon: {
    top: 4,
    left: -2,
    margin: 0,
    marginTop: 'auto',
    position: 'relative',
    '& svg': {
      width: 25,
      height: 25,
      color: palette.error.dark,
    },
  },
  value: {
    display: 'block',
    margin: '0px !important',
    fontSize: '1rem !important',
    color: palette.text.primary,
    fontFamily: typography.fontRegular,
  }
}))
