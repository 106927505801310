import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Card from '@components/Card'
import Modal from '@components/Modal'
import Button from '@components/Button'

import FormGroup from './components/FormGroup'

const PostAddEdit = props => {
  let { close, item, updateList } = props

  // console.log("item", item)
  // if (item.tags) {
  //   item.tags = item.tags.map(item => item._id)
  // }

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object().shape({
      materialName: Yup.string().required('REQUIRED'),
      inputUnit: Yup.string().required('REQUIRED'),
      outputUnit: Yup.string().required('REQUIRED'),
      conversionFactor: Yup.number().required('REQUIRED')
    })
  }

  const footer = submit => {
    return <Button onClick={submit}>CALCULATOR_ADD_MATERIAL_SEND</Button>
  }

  const onSuccess = () => {
    close()
    updateList()
  }

  const add = ({ handleSubmit, ...formik }) => {
    return (
      <Card footer={() => footer(handleSubmit)}>
        <FormGroup {...props} formik={formik} />
      </Card>
    )
  }

  const edit = ({ handleSubmit, ...formik }) => {
    return (
      <Modal
        open={item}
        close={close}
        title="CALCULATOR_EDIT_MODAL_TITLE"
        actions={<Actions onSubmit={handleSubmit} />}
      >
        <FormGroup {...props} formik={formik} />
      </Modal>
    )
  }

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => close()}>
          BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>POST_ADD_CARD_SEND</Button>
      </div>
    )
  }

  let params = {}
  if (item._id) {
    params = {
      method: 'put',
      others: item._id
    }
  } else {
    params = {
      method: 'post'
    }
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    let formData = values
    submit({ formData })
    resetForm()
  }

  return (
    <div className="PostAddEdit">
      <Axios
        {...params}
        api="calculator"
        onSuccess={onSuccess}
        headers={{ 'Content-type': "application/json;boundary='cecarbon'" }}
      >
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => (item._id ? edit(formik) : add(formik))}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default PostAddEdit
