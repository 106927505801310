import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  item: {
    padding: '2rem',
    display: 'flex',
    maxHeight: '5rem',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#F9FFD1',
    fontFamily: typography.fontRegular,
    color: palette.text.primary,
  },
  root: {
    paddingBottom: 4,
    pageBreakInside: 'avoid',
    '& .MuiGrid-item:first-child': {
      '& $item': {
        backgroundColor: palette.grey[150],
      },
    },
    '&.mack': {
      '& .MuiGrid-item': {
        '& $item': {
          fontFamily: typography.fontSemibold,
          '@media print': {
            fontSize: '1rem'
          },
        },
      },
    },
  },
}))
