import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography }) => ({
  view_report: {
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      maxWidth: 1300,
    }
  },
  notice: {
    zIndex: 10,
    marginTop: '-6rem',
    '@media print': {
      marginTop: '1rem',
    },
  },
  headerReports: {
    zIndex: 10,
    position: 'relative',
  },
  reports: {
    paddingTop: '5rem',
    marginBottom: '2rem',
    backgroundColor: 'transparent !important',
    '@media print': {
      paddingTop: '0rem',
      marginBottom: '0rem',
    },
  },
  description: {
    '& p': {
      marginTop: '2rem',
      fontSize: '1.2rem',
      textAlign: 'center',
      fontFamily: typography.fontRegular,
    },
  },
  footer: {
    display: 'flex',
    margin: '4rem 0',
    alignItems: 'center',
    justifyContent: 'center',
    '@media print': {
      display: 'none',
    },
  },
  root: {
    '& $headerReports': {
      '@media print': {
        marginTop: '0rem',
        '& div:nth-child(2)': {
          marginBottom: '0rem',
        },
      },
    },
  },
}))
