import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import AddEditHome from './components/AddEditHome'
import AddEditAbout from './components/AddEditAbout'

import './styles.scss'

const Home = () => {
  const [item, setItem] = useState({})

  const onSuccess = ({ data = {} }) => {
    setItem(data.homepage)
  }

  const tabs = [
    {
      icon: 'add',
      name: 'HOME_TAB_ADD_HOME',
      content: <AddEditHome item={item} />
    },
    {
      icon: 'add',
      name: 'HOME_TAB_ADD_ABOUT',
      content: <AddEditAbout item={item} />
    }
  ]

  return (
    <Axios run api="pages.home" onSuccess={onSuccess}>
      <div className="Home">
        <Tabs  headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  )
}

export default Home
