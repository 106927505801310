import React from 'react'

import Utils from '@utils'
import Typography from '@components/Typography'

import Tickets from './components/Tickets'

import useStyles from './styles.js'

export default function ResultsBySources({ result = {} }) {
  const classes = useStyles()
  const { sources = [] } = result

  const array = [
    {
      field: 'description',
      title: "RESULT_BY_SOURCES_HEADER_ONE"
    },
    {
      field: 'value',
      title: "RESULT_BY_SOURCES_HEADER_TWO"
    },
    {
      field: 'unit',
      title: "RESULT_BY_SOURCES_HEADER_THREE"
    },
    {
      field: 'factorEmission',
      title: "RESULT_BY_SOURCES_HEADER_FOUR"
    },
    {
      field: 'emission',
      title: "RESULT_BY_SOURCES_HEADER_FIVE"
    },
    {
      field: 'factorBionegic',
      title: "RESULT_BY_SOURCES_HEADER_SIX"
    },
    {
      field: 'biogenic',
      title: "RESULT_BY_SOURCES_HEADER_SEVEM"
    },
    {
      field: 'factorEnergy',
      title: "RESULT_BY_SOURCES_HEADER_EIGHT"
    },
    {
      field: 'energy',
      title: "RESULT_BY_SOURCES_HEADER_NINE"
    },
  ]

  const isNumber = (value) => {
    return Utils.numberFormart(value, 2)
  }

  const getRows = (row) => {
    return (<div className={classes.rows}>
      {array.map(({ field }, index) =>
        <div key={index} className={classes.itemRow}>
          {!isNaN(row[field]) ? isNumber(row[field]) : row[field]}
        </div>
      )}
    </div>)
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">RESULT_BY_SOURCES_TITLE</Typography>
      </div>
      <Tickets result={result} />
      <div className={classes.header}>
        {array.map((item, index) =>
          <div key={index} className={classes.item}>
            <Typography>{item.title}</Typography>
          </div>
        )}
      </div>
      <div className={classes.body}>
        {sources.map(item => getRows(item))}
      </div>
    </div>
  )
}
