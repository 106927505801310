import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  root: {
    borderRadius: 10,
    paddingTop: '3rem',
    position: 'relative',
    backgroundColor: palette.grey[150],
  },
  image: {
    top: -40,
    position: 'absolute',
    '& img': {
      width: '100%',
    },
    '@media print': {
      display: 'none',
    },
  },
  header: {
   
  },
  title: {
    '& h3': {
      fontSize: '2rem',
      marginTop: '2rem',
      textAlign: 'center',
      fontFamily: typography.fontSemibold,
    },
  },
  description: {
    '& p': {
      maxWidth: 650,
      marginTop: '2rem',
      fontSize: '1.2rem',
      textAlign: 'center',
      margin: 'auto',
      fontFamily: typography.fontRegular,
    },
  },
  reportTitle: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    color: palette.primary.main,
    fontFamily: typography.fontRegular,
  },
  groupItem: {
    display: 'flex',
    fontSize: '1.3rem',
    color: palette.text.primary,
    fontFamily: typography.fontRegular,
    '& span': {
      display: 'block',
      fontSize: '1.3rem',
      marginBottom: '0.8rem',
    },
    '@media print': {
      fontSize: '1.1rem',
      '& span': {
        fontSize: '1rem',
      },
    },
  },
  container: {
    margin: 'auto',
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
}))
