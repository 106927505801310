export default {
  api: {
    endpoints: {
      inputs: {
        url: 'inputs',
        getFuels: {
          url: 'inputs/getFuels'
        },
        addFuels: {
          url: 'inputs/createFuel'
        }
      },
      results: {
        url: 'results',
        filterMaterial: {
          url: "results/filter-materials"
        },
        filterFuel: {
          url: "results/filter-fuels"
        }
      },
      faqs: {
        url: 'faqs'
      },
      constructions: {
        url: 'constructions'
      },
      enterprisetypes: {
        url: 'enterprisetypes'
      },
      constants: {
        url: 'phases',
        phases: {
          url: 'constants/phases'
        },
        subtypes: {
          url: 'constants/subtypes'
        },
        enumerations: {
          url: 'constants/enumerations'
        }
      },
      clients: {
        url: 'clients',
        export: {
          url: 'clients/export'
        },
        resultsAll: {
          url: 'clients/resultsall'
        },
        results: {
          url: 'clients/results'
        }
      },
      managers: {
        url: 'managers'
      },
      administrators: {
        url: 'administrators'
      },
      depostions: {
        url: 'depostions'
      },
      notification: {
        url: 'notification'
      },
      feedbacks: {
        url: 'feedbacks'
      },
      user: {
        url: "users",
      },
      admin: {
        url: 'admin',
        aboutpage: {
          url: 'admin/aboutpage'
        },
        homepage: {
          url: 'admin/homepage'
        },
        termspage: {
          url: 'admin/termspage'
        },
        privacypage: {
          url: 'admin/privacypage'
        },
        footer: {
          url: 'admin/footer'
        },
        rodape: {
          url: 'admin/rodape'
        },
        indicators: {
          url: 'admin/indicators'
        }
      },
      pages: {
        url: 'pages',
        home: {
          url: 'pages/home'
        },
        about: {
          url: 'pages/about'
        },
        privacy: {
          url: 'pages/privacy'
        },
        terms: {
          url: 'pages/terms'
        }
      },
      posts: {
        url: 'posts',
        blog: {
          url: 'posts/blog'
        }
      },
      videos: {
        url: 'videos',
        blog: {
          url: 'videos/blog'
        }
      },
      downloads: {
        url: 'downloads',
        blog: {
          url: 'downloads/blog'
        }
      },
      capabilities: {
        url: 'capabilities',
        blog: {
          url: 'capabilities/blog'
        }
      },
      calculator: {
        url: 'calculator'
      },
      methodologys: {
        url: 'methodologys',
        blog: {
          url: 'methodologys/blog'
        }
      },
      uploads: {
        url: 'uploads'
      },
      login: {
        url: 'auth/admin'
      }
    }
  },
  user: {
    lang: 'pt-BR'
  },
  TokenSession: 'PRIECSDON695FN54',
  ObjectSession: 'R6543N56776N4345H',
  Routes: {
    Publics: ['/login', '/login/']
  }
}
