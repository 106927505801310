import React from 'react'
import clsx from 'clsx'

import Translate from '@components/Translate'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import './styles.scss'

const Modal = props => {
  const { open, close, title, children, className, ...rest } = props
  const Styles = clsx(['Modal', className])

  const actions = () => {
    if (typeof props.actions === 'function') {
      return props.actions()
    } else {
      return props.actions
    }
  }

  return (
    <div>
      <Dialog className={Styles} onClose={close} open={open} {...rest}>
        <MuiDialogTitle className="modal_header">
          <Typography variant="h6">
            <Translate>{title}</Translate>
          </Typography>
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent dividers>{children}</MuiDialogContent>
        <MuiDialogActions>{actions()}</MuiDialogActions>
      </Dialog>
    </div>
  )
}

export default Modal
