import React from 'react'

import Typography from '@components/Typography'
import TableResult from '@components/TableResult'

import Tickets from './components/Tickets'

import useStyles from './styles.js'

export default function ResultsByStepWork({ result, mack }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">ADMIN_REPORT_STEP_WORK_TITLE</Typography>
      </div>
      <Tickets result={result} />
      <TableResult array={result.summations} mack={mack} />
    </div>
  )
}
