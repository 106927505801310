import React from 'react'
import Grid from '@material-ui/core/Grid'

import RowResult from '@components/RowResult'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Actions({ result = {} }) {
  const classes = useStyles()
  const { actions = [] } = result

  const array = actions.map((item) => {
    const { description, result, type, unit } = item
    return {
      description,
      emission: type,
      biogenic: result,
      energy: unit,
    }
  })

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">ACTIONS_TITLE</Typography>
      </div>
      <Grid container spacing={1}>
        <Grid className={classes.item} item xs={4}>
          <Typography>ACTIONS_HEADER_ONE</Typography>
        </Grid>
        <Grid className={classes.item} item xs>
          <Typography>ACTIONS_HEADER_TWO</Typography>
        </Grid>
        <Grid className={classes.item} item xs>
          <Typography>ACTIONS_HEADER_THREE</Typography>
        </Grid>
        <Grid className={classes.item} item xs>
          <Typography>ACTIONS_HEADER_FOUR</Typography>
        </Grid>
      </Grid>
      {array.map((item, index) => (
        <div key={index} className={classes.item}>
          <RowResult item={item} />
        </div>
      ))}
    </div>
  )
}
