import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Utils from '@utils'

import FormGroupConstruction from './components/FormGroupConstruction'

import './styles.scss'
import Input from '@components/Input'

const ConstructionEdit = props => {
  const {
    onClose,
    construction,
    updateList,
    isOpen,
    managers,
    enterpriseTypes
  } = props

  if (!construction) return null

  const validationSchemaEdit = Yup.object().shape({
    name: Yup.string().required('REQUIRED'),
    companyName: Yup.string(),
    landArea: Yup.number().nullable(),
    totalAreaBuilt: Yup.number().nullable(),
    totalAreaDestroy: Yup.number().nullable(),
    initalCostEstimated: Yup.number().nullable(),
    finalCostCalculated: Yup.number().nullable(),
    initialDate: Yup.date().nullable(),
    finalDate: Yup.date().nullable(),
    constructiveSystem: Yup.string().nullable(),
    typeInvestment: Yup.string().nullable(),
    typeEnterprise: Yup.string().nullable(),
    categoryEnterprise: Yup.string().nullable(),
    otherCategory: Yup.string().nullable(),
    standartEnterprise: Yup.string(),
    otherEnterprise: Yup.string().nullable(),
    stage: Yup.string().nullable(),
    publicFunding: Yup.string().nullable(),
    certification: Yup.string().nullable(),
    certificationInfo: Yup.number().nullable(),
    managerId: Yup.string().nullable(),
    address: Yup.object().shape({
      street: Yup.string().required('REQUIRED'),
      zipCode: Yup.string().required('REQUIRED'),
      city: Yup.string().required('REQUIRED'),
      state: Yup.string().required('REQUIRED'),
      complement: Yup.string().nullable()
    })
  })

  const schema = {
    enableReinitialize: true,
    initialValues: construction,
    validationSchema: validationSchemaEdit
  }

  const onSuccess = () => {
    onClose()
    updateList()
  }

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => onClose()}>
          MANAGER_BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>CONSTRUCTIONS_EDIT_SUBMIT</Button>
      </div>
    )
  }

  const params = {
    method: 'put',
    others: construction?.id
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    console.log('submit', values)

    const {
      categoryEnterprise,
      otherCategory,
      standartEnterprise,
      otherStandard
    } = values

    submit({
      params: {
        ...values,
        categoryEnterprise: otherCategory ? otherCategory : categoryEnterprise,
        standartEnterprise: otherStandard ? otherStandard : standartEnterprise
      }
    })
    // resetForm()
  }

  return (
    <div className="Edit">
      <Axios api="constructions" {...params} onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {({ handleSubmit, ...formik }) => (
              <Modal
                open={isOpen}
                close={onClose}
                title="CONSTRUCTIONS_EDIT_MODAL_TITLE"
                actions={<Actions onSubmit={handleSubmit} />}
              >
                <FormGroupConstruction
                  {...props}
                  formik={formik}
                  enterpriseTypes={enterpriseTypes}
                  managers={managers}
                />
              </Modal>
            )}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default ConstructionEdit
