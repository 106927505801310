import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'
import { last } from 'lodash'
import AddressEditor from '../../../../../AddressEditor'

const FormGroupConstruction = props => {
  const { formik, enterpriseTypes, managers } = props
  const { handleBlur, handleChange, values, errors, setFieldValue } = formik

  const typeInvestment = ['Vertical', 'Horizontal']
  const typeEnterprise = enterpriseTypes.map(item => item.name)
  const categoryEnterprise = [
    'Residencial',
    'Comerial',
    'Indústria',
    'Misto - Especificar',
    'Outros - Especificar'
  ]
  const standartEnterprise = [
    'Habitação de Interesse Social (HIS)',
    'Média',
    'Alto',
    'Outros - Especificar'
  ]

  const stages = [
    { name: 'Projeto', value: 'Projeto' },
    { name: 'Obra', value: 'Obra' },
  ]

  const managersSelect = managers.map(({ id, user }) => ({
    value: id,
    label: user.name
  }))

  const typeRegisters = [
    { name: 'Obra Real', value: 'Obra Real'},
    { name: 'Obra Teste', value: 'Obra Teste'}
  ]

  const onChange = event => {
    const { name, value } = event.target
    const key =
      name === 'categoryEnterprise' ? 'otherCategory' : 'otherStandard'
    setValueOther(key, name, value)
    handleChange(event)
  }

  const verifyOther = (value, array) => {
    return (
      value === last(array) || (!array.includes(String(value).trim()) && value)
    )
  }

  const setValueOther = (key, name, value) => {
    const array =
      key === 'otherCategory' ? categoryEnterprise : standartEnterprise

    console.log({
      key,
      name,
      value,
      array
    })

    if (value && value !== last(array)) {
      if (!array.includes(value)) {
        setFieldValue(key, value)
        setFieldValue(name, last(array))
      } else {
        setFieldValue(key, null)
      }
    }
  }

  useEffect(() => {
    const {
      categoryEnterprise: category,
      standartEnterprise: standart
    } = values
    setValueOther('otherCategory', 'categoryEnterprise', category)
    setValueOther('otherStandard', 'standartEnterprise', standart)
  }, [values.standartEnterprise || values.categoryEnterprise])

  return (
    <>
      <Grid item xs className="FormGroupClient">
        <Grid item>
          <Input
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CONSTRUCTIONS_EDIT_INPUT_LABEL_NAME"
            value={Utils.getValue(values, 'name')}
            error={Utils.getValue(errors, 'name')}
            helperText={Utils.getValue(errors, 'name')}
          />
          <Input
            name="companyName"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CONSTRUCTIONS_EDIT_INPUT_LABEL_COMPANY_NAME"
            value={Utils.getValue(values, 'companyName')}
            error={Utils.getValue(errors, 'companyName')}
            helperText={Utils.getValue(errors, 'companyName')}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <Input
              name="landArea"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_LAND_AREA"
              value={Utils.getValue(values, 'landArea')}
              error={Utils.getValue(errors, 'landArea')}
              helperText={Utils.getValue(errors, 'landArea')}
              type="number"
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              name="totalAreaBuilt"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_BUILD_AREA"
              value={Utils.getValue(values, 'totalAreaBuilt')}
              error={Utils.getValue(errors, 'totalAreaBuilt')}
              helperText={Utils.getValue(errors, 'totalAreaBuilt')}
              type="number"
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              name="totalAreaDestroy"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_AREA_DESTROY"
              value={Utils.getValue(values, 'totalAreaDestroy')}
              error={Utils.getValue(errors, 'totalAreaDestroy')}
              helperText={Utils.getValue(errors, 'totalAreaDestroy')}
              type="number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Input
              name="initalCostEstimated"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_INITIAL_COST"
              value={Utils.getValue(values, 'initalCostEstimated')}
              error={Utils.getValue(errors, 'initalCostEstimated')}
              helperText={Utils.getValue(errors, 'initalCostEstimated')}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              name="finalCostCalculated"
              type="number"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_FINAL_COST"
              value={Utils.getValue(values, 'finalCostCalculated')}
              error={Utils.getValue(errors, 'finalCostCalculated')}
              helperText={Utils.getValue(errors, 'finalCostCalculated')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Grid item>
              <Input
                name="initialDate"
                type="date"
                onBlur={handleBlur}
                onChange={handleChange}
                label="CONSTRUCTIONS_EDIT_INPUT_LABEL_START_DATA"
                value={Utils.getValue(values, 'initialDate')}
                error={Utils.getValue(errors, 'initialDate')}
                helperText={Utils.getValue(errors, 'initialDate')}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              type="date"
              name="finalDate"
              onBlur={handleBlur}
              onChange={handleChange}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_END_DATA"
              value={Utils.getValue(values, 'finalDate')}
              error={Utils.getValue(errors, 'finalDate')}
              helperText={Utils.getValue(errors, 'finalDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Input
              name="constructiveSystem"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'constructiveSystem')}
              error={Utils.getValue(errors, 'constructiveSystem')}
              helperText={Utils.getValue(errors, 'constructiveSystem')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_CONSTRUCTION_SYSTEM"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type="select"
              name="typeEnterprise"
              array={typeEnterprise}
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'typeEnterprise')}
              error={Utils.getValue(errors, 'typeEnterprise')}
              helperText={Utils.getValue(errors, 'typeEnterprise')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_DEVELOPMENT"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type="select"
              onBlur={handleBlur}
              name="typeInvestment"
              array={typeInvestment}
              onChange={handleChange}
              value={Utils.getValue(values, 'typeInvestment')}
              error={Utils.getValue(errors, 'typeInvestment')}
              helperText={Utils.getValue(errors, 'typeInvestment')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_INVESTMENT"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              type="select"
              array={categoryEnterprise}
              name="categoryEnterprise"
              onBlur={handleBlur}
              onChange={onChange}
              value={Utils.getValue(values, 'categoryEnterprise')}
              error={Utils.getValue(errors, 'categoryEnterprise')}
              helperText={Utils.getValue(errors, 'categoryEnterprise')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_DEVELOPMENT_CATEGORY"
            />
          </Grid>
          {values.categoryEnterprise === 'Misto - Especificar' ||
            (values.categoryEnterprise === 'Outros - Especificar' && (
              <Grid item xs={6}>
                <Input
                  onBlur={handleBlur}
                  name="otherCategory"
                  onChange={handleChange}
                  value={Utils.getValue(values, 'otherCategory')}
                  error={Utils.getValue(errors, 'otherCategory')}
                  helperText={Utils.getValue(errors, 'otherCategory')}
                  label="CONSTRUCTIONS_EDIT_INPUT_LABEL_DEVELOPMENT_OTHER"
                />
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              type="select"
              array={standartEnterprise}
              name="standartEnterprise"
              onBlur={handleBlur}
              onChange={onChange}
              value={Utils.getValue(values, 'standartEnterprise')}
              error={Utils.getValue(errors, 'standartEnterprise')}
              helperText={Utils.getValue(errors, 'standartEnterprise')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_ENTERPRISE_STANDARD"
              help="CONSTRUCTIONS_EDIT_INPUT_HELP_ENTERPRISE_STANDARD"
            />
          </Grid>
          {verifyOther(values.standartEnterprise, standartEnterprise) && (
            <Grid item xs={6}>
              <Input
                onBlur={handleBlur}
                name="otherStandard"
                onChange={handleChange}
                value={Utils.getValue(values, 'otherStandard')}
                error={Utils.getValue(errors, 'otherStandard')}
                helperText={Utils.getValue(errors, 'otherStandard')}
                label="CONSTRUCTIONS_EDIT_INPUT_LABEL_DEVELOPMENT_OTHER"
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Input
              name="stage"
              type="select"
              array={stages}
              nameValue="value"
              nameText="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'stage')}
              error={Utils.getValue(errors, 'stage')}
              helperText={Utils.getValue(errors, 'stage')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_ENTERPRISE_STAGE"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type="select"
              name="publicFunding"
              array={['Sim', 'Não']}
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'publicFunding')}
              error={Utils.getValue(errors, 'publicFunding')}
              helperText={Utils.getValue(errors, 'publicFunding')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_PUBLIC_FUNDING"
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              type="select"
              name="managerId"
              nameValue="value"
              nameText="label"
              array={managersSelect}
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'managerId')}
              error={Utils.getValue(errors, 'managerId')}
              helperText={Utils.getValue(errors, 'managerId')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_MANAGER"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Input
              name="typeRegister"
              type="select"
              array={typeRegisters}
              nameValue="value"
              nameText="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'typeRegister')}
              error={Utils.getValue(errors, 'typeRegister')}
              helperText={Utils.getValue(errors, 'typeRegister')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_REGISTER"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              type="select"
              array={['Sim', 'Não']}
              name="certification"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'certification')}
              error={Utils.getValue(errors, 'certification')}
              helperText={Utils.getValue(errors, 'certification')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_PUBLIC_CERTIFICATION"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name="certificationInfo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'certificationInfo')}
              error={Utils.getValue(errors, 'certificationInfo')}
              helperText={Utils.getValue(errors, 'certificationInfo')}
              label="CONSTRUCTIONS_EDIT_INPUT_LABEL_INFORM_CERTIFICATION"
            />
          </Grid>
        </Grid>
        <AddressEditor formik={formik} />
      </Grid>
    </>
  )
}

export default FormGroupConstruction
