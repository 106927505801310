import configs from '@configs/'

class UserUtils {
  getPermission() {
    const item = this.getObjectToken()
    return item.permission
  }

  getUser() {
    const item = this.getObjectToken()
    return item
  }

  getObjectToken() {
    const token = sessionStorage.getItem(configs.TokenSession)
    if (token) {
      let user = Buffer.from(String(token).split('.')[1], 'base64').toString()
      return JSON.parse(user)
    }
  }

  setUserToken(user) {
    const Token = this.getObjectToken()
    Token.user = user
    sessionStorage.setItem(configs.ObjectSession, JSON.stringify(Token))
  }
}

export default new UserUtils()
