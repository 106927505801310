import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  root: {
    paddingTop: '4rem',
    paddingBottom: '8rem',
    backgroundColor: palette.grey[150],
    '@media print': {
      paddingTop: '2rem',
      paddingBottom: '2rem', 
      backgroundColor: palette.grey[150],
      '-webkit-print-color-adjust': 'exact',
    },
  },
  container: {
    margin: 'auto',
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  row: {
    margin: 'auto',
    '@media print': {
      '& .MuiGrid-item ': {
        paddingTop: '0rem',
        paddingBottom: '0rem',
      },
    },
  },
  line: {
    margin: 'auto',
  },
  title: {
    '& h3': {
      fontSize: '2rem',
      textAlign: 'center',
      marginBottom: '2rem',
      fontFamily: typography.fontSemibold,
      '@media print': {
        fontSize: '1.4rem',
      },
    },
  },
}))
