import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import List from './components/List'
import AddEdit from './components/AddEdit'

import './styles.scss'

const Depostions = () => {
  const [run, setRun] = useState(1)
  const [list, setList] = useState([])
  const [item, setItem] = useState({})

  const onSuccess = ({ data }) => {
    setItem({})
    setList([...data])
  }

  const updateList = () => {
    setRun(run + 1)
  }

  const tabs = [
    {
      icon: 'list',
      name: 'DEPOSITIONS_TAB_LIST',
      content: <List list={list} setItem={setItem} updateList={updateList} />
    },
    {
      icon: 'add',
      name: 'DEPOSITIONS_TAB_ADD',
      content: (
        <AddEdit
          item={item}
          updateList={updateList}
          close={() => setItem({})}
        />
      )
    }
  ]

  return (
    <Axios run={run} api="depostions" onSuccess={onSuccess}>
      <div className="Depostions">
        <Tabs headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  )
}

export default Depostions
