import React from 'react'
import Grid from '@material-ui/core/Grid'

import Ticket from '@components/Ticket'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Indicators({ result = {} }) {
  const classes = useStyles()

  const { indicators = {} } = result

  const rows = [
    {
      label: 'INDICATORS_LABEL_ONE',
      value: 'averageEmissionByArea',
    },
    {
      label: 'INDICATORS_LABEL_TWO',
      value: 'averageEmissionByCost',
    },
    {
      label: 'INDICATORS_LABEL_FOUR',
      value: 'averageEnergyByArea',
    },
    {
      label: 'INDICATORS_LABEL_FIVE',
      value: 'averageEnergyByCost',
    },
  ]

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">INDICATORS</Typography>
      </div>
      <div className={classes.main}>
        <Grid className={classes.container} container spacing={10} xs={10}>
          {rows.map((item, index) => (
            <Grid key={index} item xs={6}>
              <Ticket title={item.label} value={indicators[item.value]} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
