import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

// core components
import Login from '@pages/Login'
import Admin from '@layouts/Admin.js'
import { ThemeProvider } from '@material-ui/core/styles'

import { UserConsumer } from '@contexts/User'
import Load, { LoadProvider } from '@contexts/Load'
import { UserProvider, UserValidate } from '@contexts/User'

import theme from './theme'

import 'react-toastify/dist/ReactToastify.css'
import '@css/material.css?v=1.8.0'

import '@sass/fonts.scss'
import '@sass/global.scss'

const hist = createBrowserHistory()

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <UserProvider>
      <LoadProvider>
        <Router history={hist}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/">
              {props => (
                <UserConsumer>
                  {({ user }) => (
                    <UserValidate {...props}>
                      <Route
                        path="/admin"
                        component={() => <Admin user={user} />}
                      />
                      {/* <Redirect from="/" to="/admin/clients" /> */}
                    </UserValidate>
                  )}
                </UserConsumer>
              )}
            </Route>
          </Switch>
        </Router>
        <ToastContainer />
        <Load />
      </LoadProvider>
    </UserProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
