import React from 'react'
import Image from '@editorjs/image'
import EditorJs from 'react-editor-js'

import { http } from '@components/Axios'

import { EDITOR_JS_TOOLS } from './constants'

import './styles.scss'

const ReactEditor = props => {
  const { name, value } = props

  const onChange = (object, { blocks }) => {
    const event = {
      target: {
        name,
        value: blocks
      }
    }
    props.handleChange(event)
  }

  const tools = {
    ...EDITOR_JS_TOOLS,
    image: {
      class: Image,
      config: {
        uploader: {
          uploadByFile(file) {
            const formData = new FormData()
            formData.append('image', file)
            return http({
              formData,
              method: 'post',
              api: 'uploads',
              headers: {
                'Content-type': "multipart/form-data;boundary='cecarbon'"
              }
            }).then(({ data }) => {
              return {
                success: 1,
                file: {
                  url: data.data
                }
              }
            })
          }
        }
      }
    }
  }

  return (
    <div className="EditorJs">
      <EditorJs data={{ blocks: value }} tools={tools} onChange={onChange} />
    </div>
  )
}

export default ReactEditor
