import React from 'react'
import clsx from 'clsx'

import Icon from '@components/Icon'

import useStyles from './styles.js'

export default function Rate(props) {
  const classes = useStyles()
  const { children, type } = props
  const error = type === 'error'
  return (
    <div className={clsx(classes.root, [type])}>
      <span className={classes.value}>{children}%</span>
      <Icon className={classes.icon} name={error ? 'ArrowDropUp' : 'ArrowDropDown'} />
    </div>
  )
}
