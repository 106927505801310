import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Card from '@components/CardFull'
import Button from '@components/Button'
import Translate from '@components/Translate'
import CardBody from '@components/CardFull/CardBody'
import CardHeader from '@components/CardFull/CardHeader'
import Axios from '@components/Axios'

import CardView from './components/CardView'
import TableList from './components/TableList'

import styles from '@js/material/views/dashboardStyle'
import './styles.scss'

const useStyles = makeStyles(styles)

export default function Dashboard() {
  const classes = useStyles()

  const [indicators, setIndicators] = useState({})

  const onSuccess = ({ data }) => {
    setIndicators(data)
  }

  const onSuccessExport = (data) => {
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
    link.target = '_blank';
    link.download = 'report.csv';
    link.click();
  }

  return (
    <Axios run api="admin.indicators" onSuccess={onSuccess}>
      <div className="Dashboard">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              icon="Store"
              color="primary"
              value={indicators.creators}
              title="CLIENTS_CARD_VIEW_ONE_TITLE"
              description="CLIENTS_CARD_VIEW_ONE_DESCRIPTION"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              color="primary"
              icon="Apartment"
              value={indicators.constructions}
              title="CLIENTS_CARD_VIEW_TWO_TITLE"
              description="CLIENTS_CARD_VIEW_TWO_DESCRIPTION"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              color="primary"
              icon="LocalCafe"
              value={indicators.managers}
              title="CLIENTS_CARD_VIEW_THREE_TITLE"
              description="CLIENTS_CARD_VIEW_THREE_DESCRIPTION"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              color="primary"
              icon="LocalOffer"
              value={indicators.city}
              title="CLIENTS_CARD_VIEW_FOUR_TITLE"
              description="CLIENTS_CARD_VIEW_FOUR_DESCRIPTION"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              icon="Timeline"
              color="primary"
              value={indicators.totalEmission}
              title="CLIENTS_CARD_VIEW_FIVE_TITLE"
              description="CLIENTS_CARD_VIEW_FIVE_DESCRIPTION"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CardView
              color="primary"
              icon="FlashAuto"
              value={indicators.totalEnergyGJ}
              title="CLIENTS_CARD_VIEW_SIX_TITLE"
              description="CLIENTS_CARD_VIEW_SIX_DESCRIPTION"
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <div>
                  <h4 className={classes.cardTitleWhite}>
                    <Translate>CLIENTS_CARD_HEADER_TABLE_LIST_TITLE</Translate>
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    <Translate>
                      CLIENTS_CARD_HEADER_TABLE_LIST_DESCRIPTION
                    </Translate>
                  </p>
                </div>
                <div className={classes.cardHeaderAction}>
                  <Axios api="clients.resultsAll" onSuccess={onSuccessExport}>
                    {({ submit }) => (
                      <Button onClick={submit} color="success">
                        CONSTRUCTIONS_CARD_HEADER_ACTION_EXPORT
                      </Button>
                    )}
                  </Axios>
                  <Axios api="clients.export" onSuccess={onSuccessExport}>
                    {({ submit }) => (
                      <Button onClick={submit} color="success">
                        CLIENTS_CARD_HEADER_ACTION_EXPORT
                      </Button>
                    )}
                  </Axios>
                </div>
              </CardHeader>
              <CardBody>
                <TableList />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Axios>
  )
}
