import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  title: {
    fontSize: '1.4rem',
    textAlign: 'center',
    color: palette.common.white,
    '@media print': {
      fontSize: '1.1rem',
    },
  },
  value: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
    color: palette.common.white,
    fontFamily: typography.fontRegular
  },
  number: {
    fontSize: '1.8rem',
    '@media print': {
      fontSize: '1.4rem',
    },
  },
  unity: {
    marginLeft: 5,
    display: 'block',
    fontSize: '1rem',
  },
  root: {
    borderRadius: 20,
    padding: '1rem 0',
    backgroundColor: palette.primary.main,
    '&.success': {
      backgroundColor: '#E4EABE',
      '& $title': {
        color: palette.primary.main,
      },
      '& $value': {
        color: palette.text.primary,
      },
    },
    '&.error': {
      backgroundColor: '#FC9A9A',
      '& $value': {
        color: palette.text.primary,
      },
    },
  },
}))
