import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette }) => ({
  root: {
    marginTop: '5rem',
    '@media print': {
      marginTop: '1rem'
    },
  },
  title: {
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginBottom: '4rem',
      fontFamily: typography.fontSemibold,
      '@media print': {
        marginBottom: '2rem'
      },
    },
  },
  item: {
    textAlign: 'center',
  },
}))
