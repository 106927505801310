import React from 'react'
import Grid from '@material-ui/core/Grid'

import RowResult from '@components/RowResult'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function ResultsByScope({ result = {} }) {
  const classes = useStyles()
  const { scopes = [] } = result
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">RESULT_BY_SCOPE_TITLE</Typography>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid className={classes.item} item xs>
          <Typography>RESULT_BY_SCOPE_HEADER_ONE</Typography>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs></Grid>
      </Grid>
      {scopes.map((item, index) => (
        <div key={index} className={classes.item}>
          <RowResult item={item} />
        </div>
      ))}
    </div>
  )
}
