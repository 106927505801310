import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'

const FormGroupManager = props => {

  const { formik } = props
  const { handleBlur, handleChange, values, errors, setFieldValue } = formik

  const { permissions = [] } = values

  const onChange = ({ target: { name, value } }) => {
    if (value) {
      permissions.push(name)
    } else {
      const index = permissions.indexOf(name)
      if (index !== -1) {
        permissions.splice(index, 1)
      }
    }
    setFieldValue('permissions', permissions)
  }

  const rules = [
    {
      name: 'CLIENTS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_CLIENTS'
    },
    {
      name: 'MANAGER',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_MANAGER'
    },
    {
      name: 'INPUTS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_INPUTS'
    },
    {
      name: 'FUELS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FUELS'
    },
    {
      name: 'HOMEPAGE',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_HOME_PAGE'
    },
    {
      name: 'DEPOSTIONS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_DEPOSTIONS'
    },
    {
      name: 'FEEDBACKS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FEEDBACKS'
    },
    {
      name: 'ABOUTPAGE',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_ABOUT_PAGE'
    },
    {
      name: 'TERMS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_TERMS'
    },
    {
      name: 'PRIVACY',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_PRIVACY'
    },
    {
      name: 'FAQ',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FAQ'
    },
    {
      name: 'POSTS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_POSTS'
    },
    {
      name: 'VIDEOS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_VIDEOS'
    },
    {
      name: 'DOWNLOADS',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_DOWNLOADS'
    },
    {
      name: 'FOOTER',
      label: 'MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FOOTER'
    }
  ]

  const getFieldPassword = () => {
    if (!values._id) {
      return (
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            type="password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={Utils.getValue(values, 'password')}
            error={Utils.getValue(errors, 'password')}
            label="MANAGER_ADD_EDIT_INPUT_LABEL_PASSWORD"
            helperText={Utils.getValue(errors, 'password')}
          />
        </Grid>
      )
    }
  }

  return (
    <>
      <Grid item xs className="FormGroupManager">
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            label="MANAGER_ADD_EDIT_INPUT_LABEL_NAME"
            value={Utils.getValue(values, 'name')}
            error={Utils.getValue(errors, 'name')}
            helperText={Utils.getValue(errors, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            label="MANAGER_ADD_EDIT_INPUT_LABEL_EMAIL"
            value={Utils.getValue(values, 'email')}
            error={Utils.getValue(errors, 'email')}
            helperText={Utils.getValue(errors, 'email')}
          />
        </Grid>
        {getFieldPassword()}
        <Grid item container className="checkbox-group-item">
          {rules.map((item, index) => (
            <Grid item xs={6} sm={6}>
              <Input
                {...props}
                key={index}
                type="checkbox"
                name={item.name}
                label={item.label}
                onBlur={handleBlur}
                onChange={onChange}
                value={permissions.includes(item.name)}
                className="checkbox-group-item-input"
                error={Utils.getValue(errors, 'rules')}
                helperText={Utils.getValue(errors, 'rules')}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default FormGroupManager
