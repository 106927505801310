import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

const FormGroup = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik
  return (
    <Grid item xs className="mg-auto">
      <Grid item xs={12} sm={12}>
        <Input
          {...props}
          name="title"
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, 'title')}
          error={Utils.getValue(errors, 'title')}
          label="HOME_ADD_EDIT_INPUT_LABEL_TITLE"
          helperText={Utils.getValue(errors, 'title')}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Input
          {...props}
          type="ckeditor"
          name="subtitle"
          onBlur={handleBlur}
          onChange={handleChange}
          label="HOME_ADD_EDIT_INPUT_LABEL_SUB_TITLE"
          value={Utils.getValue(values, 'subtitle')}
          error={Utils.getValue(errors, 'subtitle')}
          helperText={Utils.getValue(errors, 'subtitle')}
        />
      </Grid>
    </Grid>
  )
}

export default FormGroup
