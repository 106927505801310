import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Card from '@components/Card'
import Modal from '@components/Modal'
import Button from '@components/Button'

import FormGroup from './components/FormGroup'

import JsonFormData from 'json-form-data'

import Toast from '@utils/toast.utils'

const PostAddEdit = props => {
  let { close, item, updateList } = props

  if (item.tags) {
    item.tags = item.tags.map(item => item._id)
  }

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('REQUIRED'),
      author: Yup.string().required('REQUIRED'),
      summary: Yup.string().required('REQUIRED'),
      banner: Yup.string().required('REQUIRED'),
      blocks: Yup.string().required('REQUIRED'),
      status: Yup.string().required('REQUIRED')
    })
  }

  const footer = (submit, formik) => {
    return <Button onClick={submit} disabled={!formik.values.blocks}>POST_ADD_CARD_SEND</Button>
  }

  const onSuccess = () => {
    close()
    updateList()
  }

  const add = ({ handleSubmit, ...formik }) => {
    return (
      <Card footer={() => footer(handleSubmit, formik)}>
        <FormGroup {...props} formik={formik} />
      </Card>
    )
  }

  const edit = ({ handleSubmit, ...formik }) => {
    return (
      <Modal
        open={item}
        close={close}
        title="POST_EDIT_MODAL_TILE"
        actions={<Actions onSubmit={handleSubmit} />}
      >
        <FormGroup {...props} formik={formik} />
      </Modal>
    )
  }

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => close()}>
          BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>POST_ADD_CARD_SEND</Button>
      </div>
    )
  }

  let params = {}
  if (item._id) {
    params = {
      method: 'put',
      others: item._id
    }
  } else {
    params = {
      method: 'post'
    }
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    let options = {
      includeNullValues: false,
      showLeafArrayIndexes: true,
      initialFormData: new FormData()
    }
    let formData = JsonFormData(values, options)
    submit({ formData })
    resetForm()
    Toast.sendMessage('success', "Post Adicionado com sucesso. ", false)
  }

  return (
    <div className="PostAddEdit">
      <Axios
        {...params}
        api="posts"
        onSuccess={onSuccess}
        headers={{ 'Content-type': "multipart/form-data;boundary='cecarbon'" }}
      >
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => (item._id ? edit(formik) : add(formik))}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default PostAddEdit
