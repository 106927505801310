import { successColor, whiteColor, grayColor, hexToRgb } from '@js/material.js'

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '0px',
      width: '16px',
      height: '16px',
      marginLeft: '3px',
      marginRight: '3px',
      position: 'relative'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '12px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    margin: '0',
    marginTop: '0',
    fontSize: '14px',
    marginBottom: '0',
    color: 'rgba(' + hexToRgb(whiteColor) + ',.62)'
  },
  cardHeaderAction: {
    display: 'flex',
    gap: '25px',
    margin: 'auto 0'
  },
  cardTitle: {
    fontSize: 20,
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  }
}

export default dashboardStyle
