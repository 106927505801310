import React from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'

const FormGroupManager = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik

  return (
    <>
      <Grid item xs className="FormGroupClient">
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="user.name"
            onBlur={handleBlur}
            onChange={handleChange}
            autocomplete="off"
            label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_NAME"
            value={Utils.getValue(values, 'user.name')}
            error={Utils.getValue(errors, 'user.name')}
            helperText={Utils.getValue(errors, 'user.name')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="user.email"
            onBlur={handleBlur}
            onChange={handleChange}
            autocomplete="off"
            label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_EMAIL"
            value={Utils.getValue(values, 'user.email')}
            error={Utils.getValue(errors, 'user.email')}
            helperText={Utils.getValue(errors, 'user.email')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="phone"
            onBlur={handleBlur}
            onChange={handleChange}
            autocomplete="off"
            label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_PHONE"
            value={Utils.getValue(values, 'phone')}
            error={Utils.getValue(errors, 'phone')}
            helperText={Utils.getValue(errors, 'phone')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="position"
            onBlur={handleBlur}
            onChange={handleChange}
            autocomplete="off"
            label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_POSITION"
            value={Utils.getValue(values, 'position')}
            error={Utils.getValue(errors, 'position')}
            helperText={Utils.getValue(errors, 'position')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="department"
            onBlur={handleBlur}
            onChange={handleChange}
            autocomplete="off"
            label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_DEPARTMENT"
            value={Utils.getValue(values, 'department')}
            error={Utils.getValue(errors, 'department')}
            helperText={Utils.getValue(errors, 'department')}
          />
        </Grid>

        <Grid container xs={12} sm={12} spacing={2}>
          <Grid item xs={6} sm={6}>
            <Input
              {...props}
              name="registrationType"
              onBlur={handleBlur}
              onChange={handleChange}
              autocomplete="off"
              label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_REGISTRATION_TYPE"
              value={Utils.getValue(values, 'registrationType')}
              error={Utils.getValue(errors, 'registrationType')}
              helperText={Utils.getValue(errors, 'registrationType')}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              {...props}
              name="registrationNumber"
              onBlur={handleBlur}
              autocomplete="off"
              onChange={handleChange}
              label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_REGISTRATION_NUMBER"
              value={Utils.getValue(values, 'registrationNumber')}
              error={Utils.getValue(errors, 'registrationNumber')}
              helperText={Utils.getValue(errors, 'registrationNumber')}
            />
          </Grid>
        </Grid>
        {Utils.getValue(values, 'id') && (
          <Grid item xs={12} sm={12}>
            <Input
              placeholder="CLIENTS_EDIT_INPUT_LABEL_COMPLEMENT"
              type="password"
              name="password"
              autocomplete="new-password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'password')}
              error={Utils.getValue(errors, 'password')}
              helperText={Utils.getValue(errors, 'password')}
              label="CLIENTS_EDIT_MANAGER_INPUT_LABEL_PASSWORD"
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default FormGroupManager
