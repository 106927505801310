import React, { memo } from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'

function NumberFormatCustom(props) {
  const { inputRef, onChange, disabled, ...other } = props
  return (
    <NumberFormat
      {...other}
      disabled={disabled}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
      decimalSeparator={','}
      thousandSeparator={'.'}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const NumberFormatComponent = (props) => {
  const { label, name, value, disabled, helperText, error, formik = {} } = props

  const handleChange = ({ target: { value } }) => {
    if (value) {
      const event = {
        target: {
          name,
          value,
        },
      }
      props.handleChange(event)
    }
  }

  const onBlur = () => {
    const event = {
      target: {
        name,
        value,
      },
    }
    if (formik.setFieldTouched) {
      formik.setFieldTouched(name, true)
    }
    props.handleChange(event)
  }

  return (
    <TextField
      type={null}
      name={name}
      label={label}
      error={error}
      value={value}
      onBlur={onBlur}
      variant="outlined"
      disabled={disabled}
      helperText={helperText}
      onChange={handleChange}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  )
}

export default memo(NumberFormatComponent)
