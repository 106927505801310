import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  card: {
    width: "100%",
    backgroundColor: palette.primary.main,
    color: "#fff",
    textAlign: 'center',
    fontFamily: typography.fontSemibold,
    marginBottom: '4vh',
    '&:hover': {
      backgroundColor: palette.primary.dark,
    }
  },
  icon: {
    color: "#fff",
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    '& button': {
      margin: '0.5vw'
    }
  },
}))
