import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography }) => ({
  root: {
    marginBottom: '10rem',
  },
  row: {
    margin: 'auto',
  },
  title: {
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginBottom: '2rem',
      fontFamily: typography.fontSemibold,
      '@media print': {
        marginBottom: '1rem',
      },
    },
  }
}))
