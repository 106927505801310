import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@components/Icon';
import Axios from '@components/Axios';
import Html from '@components/Html';
import './styles.scss';

const List = ({ list = [], setItem, updateList }) => {
  if (list.length === 0) {
    return <p>Nenhuma notificação encontrada.</p>;
  }

  const notification = list[0];

  return (
    <div className="NotificationPreview">
      <h3>{notification.title}</h3>
      <Html>{notification.content}</Html>

      {/* <div className="options">
        <IconButton onClick={() => setItem(notification)} className="list-edit">
          <Icon size={30} name="edit" />
        </IconButton>
        <Axios
          api="notification"
          method="delete"
          others={notification._id}
          onSuccess={updateList}
        >
          {({ submit }) => (
            <IconButton onClick={submit} className="list-delete">
              <Icon size={30} name="delete_icon" />
            </IconButton>
          )}
        </Axios>
      </div> */}
    </div>
  );
};

List.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func,
};

export default List;
