import clsx from 'clsx'
import React from 'react'

import Utils from '@utils'
import Grid from '@material-ui/core/Grid'

import useStyles from './styles.js'

export default function RowResult(props) {
  const classes = useStyles()
  const { item, className, mack = [] } = props

  const getItem = (item, col = true) => {
    return (
      <Grid item xs={col}>
        {(item || item === 0) && <div className={classes.item}>{!isNaN(item) ? isNumber(item) : item}</div>}
      </Grid>
    )
  }

  const isNumber = (value) => {
    return Utils.numberFormart(value, 4)
  }

  const description = item.description
  
  const result = mack.find((item) => Utils.transform(item) === Utils.transform(description))

  return (
    <Grid className={clsx([className, classes.root], { mack: !!result })} container spacing={1}>
      {getItem(item.description, 4)}
      {getItem(item.emission)}
      {getItem(item.biogenic)}
      {getItem(item.energy)}
    </Grid>
  )
}
