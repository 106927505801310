import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

const FormGroup = props => {
  const { formik } = props
  const { handleBlur, handleChange, values = {}, errors } = formik
  return (
    <>
      <Grid container spacing={3}>
        <Grid item container spacing={3}>
          <Grid item xs>
            <Input
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              label="ENTERPRISETYPE_ADD_INPUT_LABEL_SUB_NAME"
              value={Utils.getValue(values, 'name')}
              error={Utils.getValue(errors, 'name')}
              helperText={Utils.getValue(errors, 'name')}
            />
          </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default FormGroup
