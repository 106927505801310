import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  root: {},
  title: {
    '& h1': {
      fontSize: '2.2rem',
      textAlign: 'center',
      marginBottom: '3rem',
      color: palette.primary.main,
      fontFamily: typography.fontSemibold,
      '@media print': {
        marginTop: '1rem',
        fontSize: '1.8rem',
        marginBottom: '1rem',
      },
    },
  },
  description: {
    marginBottom: '4rem',
    padding: "0rem 10rem",
    '& p': {
      fontSize: '1.2rem',
      marginBottom: '2rem',
      color: palette.text.primary,
      fontFamily: typography.fontRegular,
    },
  },
}))
