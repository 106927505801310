import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Modal from '@components/Modal'
import Button from '@components/Button'

import FormGroupClient from './components/FormGroupClient'

import './styles.scss'

const Edit = props => {
  let { onClose, item, updateClient, isOpen } = props

  const validationSchemaEdit = Yup.object().shape({
    name: Yup.string().required('REQUIRED'),
    email: Yup.string()
      .email('REQUIRED')
      .required('REQUIRED'),
    password: Yup.string().nullable(),
    creator: Yup.object().shape({
      cpfCnpj: Yup.string().required('REQUIRED'),
      profession: Yup.string().nullable(),
      companyName: Yup.string().nullable(),
      address: Yup.object().shape({
        street: Yup.string().required('REQUIRED'),
        zipCode: Yup.string().required('REQUIRED'),
        city: Yup.string().required('REQUIRED'),
        state: Yup.string().required('REQUIRED'),
        complement: Yup.string().nullable()
      })
    })
  })

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: validationSchemaEdit
  }

  const onSuccess = () => {
    onClose()
    updateClient()
  }

  const edit = ({ handleSubmit, ...formik }) => {
    return (
      <Modal
        open={isOpen}
        close={onClose}
        title="CLIENTS_EDIT_MODAL_TILE"
        actions={<Actions onSubmit={handleSubmit} />}
      >
        <FormGroupClient {...props} formik={formik} />
      </Modal>
    )
  }

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => onClose()}>
          MANAGER_BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>MANAGER_ADD_CARD_SEND</Button>
      </div>
    )
  }

  const params = {
    method: 'put',
    others: item.id
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values })
    // resetForm()
  }

  return (
    <div className="Edit">
      <Axios api="clients" {...params} onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => edit(formik)}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default Edit
