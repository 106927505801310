export default {
  LANG: 'pt-BR',

  ROUTER_PROFILE: 'Perfil',
  ROUTER_TRIAL: '',

  LOAD: 'Loading',
  ADD_ITEM: 'Add item',
  EMPTY_LIST: 'Empty List',
  BUTTON_CLOSE: 'Cancelar',
  BUTTON_SEND: 'Send',
  BUTTON_CONFIRM: 'Confirm',

  REQUIRED: 'Item required',

  DELETE: 'Delete',
  TABLE_SEARCH: 'Search',
  TABLE_SELECTED: 'Selected',

  USER_PENDING: 'User Pending',

  TOKEN_EXPIRED: 'Token Expired',
  INVALID_PARAMS: 'Invalid Params',
  INVALID_REQUEST: 'Invalid Request',

  USER_CREATE_SUCCESS: 'User Create',
  USER_UPDATE_SUCCESS: 'User update',
  USER_DELETE_SUCCESS: 'User disabled',
  USER_NOT_PERMISSION: 'Invalid Permission',

  NAV_BAR_SUB_TITLE: 'Admin',
  NAV_BAR_TITLE: 'Admin Blog',
  FOOTER_TITLE: 'Admin Blog',

  LOGIN_TITLE: 'Admin',
  LOGIN_BUTTON: 'Enviar',
  LOGIN_SUB_TITLE: 'Admin',
  LOGIN_FORM_INPUT_LABEL_USER: 'E-mail',
  LOGIN_FORM_INPUT_LABEL_PASSWORD: 'Senha',

  PROFILE_EDIT_SEND: 'Enviar',
  PROFILE_EDIT_ADD_CARD_TITLE: 'Profile',

  PROFILE_TAB_EDIT: 'Profile',
  PROFILE_TABS_TITLE: 'Profile',

  ADMIN_NAV_BAR_LOGOUT: 'Logout',

  ROUTER_POST: 'Posts',
  POST_TAB_LIST: 'Post List',
  POST_TAB_ADD: 'Add Post',
  POST_TAB_TITLE: 'Add Post',
  POST_ADD_CARD_SEND: 'Publish',
  POST_ADD_CARD_TITLE: 'Describe your post, and publish',
  POST_STATUS_DRAFT: 'Draft',
  POST_STATUS_PUBLISHED: 'Publish',

  CROPPER_SEND: 'Enviar Imagem',
  CROPPER_REMOVE: 'Remover Imagem',
  CROPPER_SELECT: 'Select an imagem',

  POST_ADD_EDIT_INPUT_LABEL_TAG: 'Tag',
  POST_ADD_EDIT_INPUT_LABEL_TITLE: 'Title',
  POST_ADD_EDIT_INPUT_LABEL_IMAGE: 'Cover',
  POST_ADD_EDIT_INPUT_LABEL_DESCRIPTION: 'Article',

  POST_ADD_EDIT_INPUT_LABEL_SUB_TITLE: 'SubTitle',
  POST_ADD_EDIT_INPUT_LABEL_SLUG: 'Slug',
  POST_ADD_EDIT_INPUT_LABEL_STATUS: 'Status',

  POST_LIST_TITLE: 'Title',
  POST_LIST_OPTIONS: 'Options',
  POST_LIST_TABLE_TITLE: 'Post List',
  POST_LIST_DESCRIPTION: 'Description',
  POST_LIST_BANNER: 'Image',
  POST_LIST_SUBTITLE: 'Subtitle',
  POST_LIST_SLUG: 'Slug',

  ROUTER_TAG: 'Tags',
  TAGS_LIST_TITLE: 'Name',
  TAGS_LIST_COLOR: 'Color',
  TAGS_LIST_OPTIONS: 'Options',
  TAGS_LIST_TABLE_TITLE: 'Tags List',

  TAGS_TAB_ADD: 'Add Tags',
  TAGS_ADD_CARD_SEND: 'Send',
  USERS_LIST_NAME: 'Name',
  TAGS_ADD_CARD_TITLE: 'Adicionar Tag',
  TAGS_ADD_EDIT_INPUT_LABEL_TITLE: 'Name',
  TAGS_ADD_EDIT_INPUT_LABEL_COLOR: 'Color',

  ROUTER_USERS: 'Users',

  USERS_TAB_ADD: 'Add User',
  USERS_ADD_CARD_SEND: 'Send',
  USERS_ADD_CARD_TITLE: 'Add User',
  USERS_LIST_TABLE_TITLE: 'List User',
  USERS_ADD_EDIT_INPUT_LABEL_RULE: 'Rule',
  USERS_ADD_EDIT_INPUT_LABEL_EMAIL: 'Email',
  USERS_ADD_EDIT_INPUT_LABEL_FIRST_NAME: 'First Name',
  USERS_ADD_EDIT_INPUT_LABEL_LAST_NAME: 'Last Name',
  USERS_ADD_EDIT_INPUT_LABEL_PASSWORD: 'Password',
  USERS_ADD_EDIT_INPUT_LABEL_DESCRIPTION: 'Description',

  USERS_LIST_EMAIL: 'Email',
  USERS_TAB_LIST: 'User List',
  USERS_LIST_ROLE: 'Role',
  USERS_LIST_OPTIONS: 'User List',
  USERS_LIST_FULL_NAME: 'Full Name',
  USERS_EDIT_MODAL_TILE: 'Edit User',
  TAGS_TAB_LIST: 'Lista de tags'
}
