import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Axios from '@components/Axios'
import Modal from '@components/Modal'
import Button from '@components/Button'

import FormGroupManager from './components/FormGroupManager'

import './styles.scss'

const ManagerAddEdit = props => {
  let { onClose, item, updateList, isOpen, creatorId } = props

  const validationSchemaEdit = Yup.object().shape({
    user: Yup.object().shape({
      name: Yup.string().required('REQUIRED'),
      email: Yup.string().email('REQUIRED'),
      password: Yup.string().nullable()
    }),
    phone: Yup.string().nullable(),
    position: Yup.string().nullable(),
    department: Yup.string().nullable(),
    registrationType: Yup.string().nullable(),
    registrationNumber: Yup.string().nullable()
  })

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: validationSchemaEdit
  }

  const onSuccess = () => {
    onClose()
    updateList()
  }

  const formModal = ({ handleSubmit, ...formik }) => {
    return (
      <Modal
        open={isOpen}
        close={onClose}
        title="CLIENTS_EDIT_MANAGER_MODAL_TILE"
        actions={<Actions onSubmit={handleSubmit} />}
      >
        <FormGroupManager {...props} formik={formik} />
      </Modal>
    )
  }

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => onClose()}>
          MANAGER_BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>MANAGER_ADD_CARD_SEND</Button>
      </div>
    )
  }

  const params = {
    method: item?.id ? 'put' : 'post',
    others: item?.id || creatorId
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    console.log(values)

    submit({ params: values })
    resetForm()
  }

  return (
    <div className="Edit">
      <Axios api="managers" {...params} onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => formModal(formik)}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default ManagerAddEdit
