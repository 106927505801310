import React from 'react'
import Grid from '@material-ui/core/Grid'

import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Tickets({ result }) {
  const classes = useStyles()

  const { highestEmissionBySource = {}, highestEnergyBySource = {} } = result;

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container xs={10}>
        <Grid className={classes.row} container spacing={8}>
          <Grid item xs>
            <div className={classes.item}>
              <Typography>ADMIN_REPORT_STEP_WORK_CARD_ONE_TITLE</Typography>
              <div className={classes.value}>{highestEmissionBySource.description}</div>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.item}>
              <Typography>ADMIN_REPORT_STEP_WORK_CARD_TWO_TITLE</Typography>
              <div className={classes.value}>{highestEnergyBySource.description}</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
