import { makeStyles } from '@material-ui/core/styles'

import notice from '@images/notice.png'

export default makeStyles(({ palette, typography }) => ({
  root: {
    display: 'flex',
    minHeight: '32rem',
    position: 'relative',
    alignItems: 'center',
    backgroundSize: 'cover',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${notice})`,
    '@media print': {
      minHeight: 'auto',
      backgroundImage: 'none',
    },
  },
  main: {
    margin: 'auto',
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  iconTitle: {
    fontSize: '1.2rem',
    textAlign: 'center',
    color: palette.primary.main,
    fontFamily: typography.fontRegular,
    '& span': {
      marginRight: 5,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    marginBottom: '1rem',
    '& .Icon': {
      display: 'block',
      paddingRight: 10,
      '& svg': {
        width: 100,
        height: 100,
      },
    },
    '@media print': {
      '& .Icon': {
        '& svg': {
          width: 70,
          height: 70,
        },
      },
    },
  },
  iconGroup: {
    margin: '0 1rem',
    textAlign: 'center',
  },
  text: {
    fontSize: '1.3rem',
    maxWidth: '16rem',
    color: palette.text.title,
    fontFamily: typography.fontRegular,
    '@media print': {
      maxWidth: 'auto',
      fontSize: '1rem',
    },
  },
}))
