import React from 'react'
import clsx from 'clsx'

import Utils from '@utils'
import Rate from '@components/Rate'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Ticket(props) {
  const classes = useStyles()
  const { title, value = 0, type, rate, unity = '', decimals = 4 } = props

  const getUnity = () => {
    if (typeof unity === 'function') {
      return unity()
    } else {
      return unity
    }
  }

  const getValue = () => {
    if (typeof value === 'string') {
      return value
    } else {
      return Utils.numberFormart(value, decimals)
    }
  }

  return (
    <div className={clsx(classes.root, [type])}>
      <div className={classes.title}>
        <Typography html variant="body">
          {title}
        </Typography>
      </div>
      <div className={classes.value}>
        {rate && <Rate {...props}>{rate}</Rate>}
        <span className={classes.number}>{getValue()}</span>
        <span className={classes.unity}>{getUnity()}</span>
      </div>
    </div>
  )
}
