import React from 'react'

import Grid from '@material-ui/core/Grid'

import Utils from '@utils'
import Typography from '@components/Typography'

import RowResult from '@components/RowResult'

import useStyles from './styles.js'

const Header = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.header} container spacing={1}>
      <Grid item xs={3}>
        <div className={classes.item}>
          <Typography>TABLE_RESULT_HEADER_ONE</Typography>
        </div>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid className={classes.item} item xs>
            <Typography>TABLE_RESULT_HEADER_TWO</Typography>
          </Grid>
          <Grid className={classes.item} item xs>
            <Typography>TABLE_RESULT_HEADER_THREE</Typography>
          </Grid>
          <Grid className={classes.item} item xs>
            <Typography>TABLE_RESULT_HEADER_FOUR</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid> 
  )
}

const Group = ({ element, mack }) => {
  const classes = useStyles()
  if (Utils.isArray(element)) {
    return (
      <div className={classes.group}>
        {element.map((item, index) => (
          <RowResult key={index} item={item} mack={mack} />
        ))}
      </div>
    )
  } else {
    return <RowResult item={element} mack={mack} />
  }
}

export default function TableResult({ array = [], mack }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Header />
      {array.map((item, index) => (
        <Grid key={index} className={classes.main} container spacing={1}>
          <Grid className={classes.column} item xs={3}>
            <div className={classes.item_column}>{item.description}</div>
          </Grid>
          <Grid item xs={9}>
            {item.subcategories.map((item, index) => (
              <Group key={index} element={item} mack={mack} />
            ))}
          </Grid>
        </Grid>
      ))}
    </div>
  )
}
