import React from 'react'
import clsx from 'clsx'

import { Link } from 'react-router-dom'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Poppers from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import User from '@utils/user.utils'
import Translate from '@components/Translate'
import Button from '@components/CustomButtons/Button.js'
import styles from '@js/material/components/headerLinksStyle.js'

import './styles.scss'

const useStyles = makeStyles(styles)

export default function AdminNavbarLinks() {
  const classes = useStyles()
  const [openProfile, setOpenProfile] = React.useState(null)

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }

  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const user = User.getUser() || {}

  return (
    <div className="AdminNavbarProfile">
      <div className={classes.manager}>
        <Button color="transparent" onClick={handleClickProfile}>
          <div className="names">
            <p className={classes.linkText}>{user.name}</p>
            <p className={classes.linkText}>{user.email}</p>
          </div>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            clsx({ [classes.popperClose]: !openProfile }) +
            ' ' +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <Link to='/login'>
                      <MenuItem className={classes.dropdownItem}>
                        <Translate>ADMIN_NAV_BAR_LOGOUT</Translate>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}
