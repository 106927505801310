import menu from '@material-ui/icons/Menu'
import account from '@material-ui/icons/AccountTreeOutlined'
import mail from '@material-ui/icons/MailOutlineOutlined'
import user from '@material-ui/icons/PersonOutlineOutlined'
import chat from '@material-ui/icons/ChatBubbleOutlineOutlined'
import app from '@material-ui/icons/AppsOutlined'
import notification from '@material-ui/icons/NotificationsNoneOutlined'
import subject from '@material-ui/icons/SubjectOutlined'
import dashboard from '@material-ui/icons/DashboardOutlined';
import settings from '@material-ui/icons/SettingsOutlined';
import dots from '@material-ui/icons/MoreHorizOutlined';
import circle from '@material-ui/icons/RadioButtonUnchecked';
import trip_circle from '@material-ui/icons/TripOriginOutlined';
import close from '@material-ui/icons/Close';
import delete_icon from '@material-ui/icons/DeleteOutline';
import add from '@material-ui/icons/AddCircleOutline';
import logof from '@material-ui/icons/PowerSettingsNew';
import history from '@material-ui/icons/History';
import edit from '@material-ui/icons/Edit';
import test from '@material-ui/icons/TextsmsOutlined';
import arrow_up from '@material-ui/icons/ArrowDropUp';
import arrow_down from '@material-ui/icons/ArrowDropDown';
import list from '@material-ui/icons/List'
import remove_o from '@material-ui/icons/Remove'
import priority_high from '@material-ui/icons/PriorityHighRounded';
import checkIcon from '@material-ui/icons/Check';
import search from '@material-ui/icons/Search';
import print from '@material-ui/icons/Print';
import extension from '@material-ui/icons/Extension';
import checkIcon_o from '@material-ui/icons/CheckCircleRounded';

import GetApp from '@material-ui/icons/GetApp'

import { ReactComponent as clock } from '@images/icon/clock.svg'
import { ReactComponent as dot } from '@images/icon/dot.svg'
import { ReactComponent as task } from '@images/icon/task.svg'
import { ReactComponent as comment } from '@images/icon/comment.svg'
import { ReactComponent as tree } from '@images/icon/tree.svg'
import { ReactComponent as house } from '@images/icon/house.svg'

import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";

import Timeline from '@material-ui/icons/Timeline';
import Apartment from '@material-ui/icons/Apartment';
import FlashAuto from '@material-ui/icons/FlashAuto';
import LocalCafe from '@material-ui/icons/LocalCafe';
import Visibility from '@material-ui/icons/Visibility';
import Description from '@material-ui/icons/Description';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';

export default {
  tree,
  house,
  comment,
  dot,
  task,
  clock,
  list,
  add,
  test,
  extension,
  menu,
  arrow_down,
  remove_o,
  account,
  mail,
  user,
  chat,
  app,
  arrow_up,
  notification,
  subject,
  dashboard,
  settings,
  dots,
  circle,
  trip_circle,
  close,
  delete_icon,
  logof,
  history,
  edit,
  priority_high,
  Description,
  Visibility,
  checkIcon,
  checkIcon_o,
  search,
  print,
  Store,
  Warning,
  DateRange,
  LocalOffer,
  Update,
  ArrowUpward,
  AccessTime,
  Accessibility,
  BugReport,
  Code,
  Cloud,
  Timeline,
  Apartment,
  FlashAuto,
  LocalCafe,
  ArrowBackIos,
  ArrowForwardIos,
  NavigateBeforeIcon,
  ArrowDropDown,
  ArrowDropUp,
  VerticalAlignBottom,
  KeyboardArrowUpIcon,
  KeyboardArrowDownIcon,
  GetApp,
}
