import React from 'react'
import Grid from '@material-ui/core/Grid'

import Unity from '@components/Unity'
import Ticket from '@components/Ticket'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Reports({ object = {} }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h3">ADMIN_REPORT_ABSOLUTE_RESULTS_TITLE</Typography>
      </div>
      <Grid className={classes.container} container xs={10}>
        <Grid className={classes.row} container spacing={8}>
          <Grid item xs>
            <Ticket
              value={object.totalEmission}
              unity={() => <Unity type="tco2e" />}
              title="ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_ONE_TITLE"
            />
          </Grid>
          <Grid item xs>
            <Ticket title="ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_TWO_TITLE" unity="GJ" value={object.totalEnergyGJ} />
          </Grid>
        </Grid>
        <div className={classes.line}>OU</div>
        <Grid className={classes.row} container spacing={8}>
          <Grid item xs>
            <Ticket
              value={object.totalEmission}
              unity={() => <Unity type="tco2e" />}
              title="ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_ONE_TITLE"
            />
          </Grid>
          <Grid item xs>
            <Ticket
              unity="MWh"
              value={object.totalEnergyMWh}
              title="ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_TWO_TITLE"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
