import React, { useState } from 'react'

import Modal from '@components/Modal'
import Axios from '@components/Axios'
import Notice from '@components/Notice'
import Button from '@components/Button'
import Reports from '@components/Reports'
import Typography from '@components/Typography'
import HeaderReports from '@components/HeaderReports'

import Actions from './components/Actions'
import Indicators from './components/Indicators'
import ResultsByScope from './components/ResultsByScope'
import AbsoluteResults from './components/AbsoluteResults'
import ResultsBySources from './components/ResultsBySources'
import ResultsByStepWork from './components/ResultsByStepWork'
import ResultsByCategory from './components/ResultsByCategory'

import useStyles from './styles.js'

export default function Results({ close, id }) {
  const classes = useStyles()

  const [object, setObject] = useState({})

  const onSuccess = ({ data }) => {
    setObject(data)
  }

  const mack = [
    'Serviços Preliminares',
    'Fundações',
    'Estrutura',
    'Vedações',
    'Revestimentos (Interno e Externo)',
    'Esquadrias e Vidros',
    'Pintura (Interna e Externa)',
    'Cobertura',
    'Pavimentação e Infraestrutura',
    'Instalações Prediais',
    'Consumo de Energia Elétrica',
    'Consumo de Combustíveis - Geral',
    'Fretes - Geral (Upstream)',
    'Transporte de Resíduos (Downstream)',
  ]

  const { construction } = object

  const footerModal = () => {
    return (
      <div className="actions">
        <Button onClick={close}>
          VIEW_CLIENT_REPORT_BUTTON_CLOSE
        </Button>
      </div>
    )
  }

  return (
    <Modal
      open={id}
      close={close}
      actions={footerModal}
      title="VIEW_CLIENT_REPORT_TITLE"
      className={classes.view_report}
    >
      <Axios
        run={id}
        others={id}
        api="clients.results"
        onSuccess={onSuccess}
      >
        <div className={classes.root}>
          <HeaderReports className={classes.headerReports} />
          <Reports
            image
            object={object}
            params={construction}
            className={classes.reports}
            title="ADMIN_RESULTS_REPORTS_TITLE"
          />
          <AbsoluteResults object={object.totals} />
          <Notice className={classes.notice} object={object.totals} />
          <ResultsByStepWork mack={mack} result={object} />
          <ResultsByCategory mack={mack} result={object} />
          <ResultsByScope result={object} />
          <ResultsBySources result={object} />
          <Indicators result={object} />
          <Actions result={object} />
          <div className={classes.description}>
            <Typography html>ADMIN_RESULTS_REPORTS_DESCRIPTION</Typography>
          </div>
        </div>
      </Axios>
    </Modal>
  )
}
