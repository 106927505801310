import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Utils from '@utils'
import Axios from '@components/Axios'
import Input from '@components/Input'
import Card from '@components/CardFull'
import Button from '@components/Button'
import Translate from '@components/Translate'
import CardBody from '@components/CardFull/CardBody'
import CardHeader from '@components/CardFull/CardHeader'

import Password from './components/Password'
import ConstructionsTableList from './components/ConstructionsTableList'

import styles from '@js/material/views/dashboardStyle'

import './styles.scss'
import { IconButton } from '@material-ui/core'
import Icon from '@components/Icon'
import Edit from './components/Edit'
import ManagersTableList from './components/ManagersTableList'

const useStyles = makeStyles(styles)

export default function ViewClient() {
  const { id } = useParams()
  const classes = useStyles()
  const [constructionList, setConstructionList] = useState([])
  const [managersList, setManagersList] = useState([])
  const [client, setClient] = useState({})
  const [isEditOpen, setIsEditOpen] = useState(false)
  // const [tableListToShow, setTableListToShow] = useState('managers')
  const [tableListToShow, setTableListToShow] = useState('constructions')
  const [enterpriseTypes, setEnterpriseTypes] = useState([])
  const [run, setRun] = useState(1)

  const onSuccessEnterpriseType = ({ data }) => {
    console.log('enterprize', data)
    setEnterpriseTypes(data)
  }

  const updateList = () => {
    setRun(run + 1)
  }

  const onSuccess = ({ data }) => {
    const { constructions = [] } = Utils.getValue(data, 'creator') || {}
    const { managers = [] } = Utils.getValue(data, 'creator') || {}

    setClient(data)
    setConstructionList(constructions)
    setManagersList(managers)
  }

  const onSuccessExport = data => {
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data)
    link.target = '_blank'
    link.download = 'report.csv'
    link.click()
  }

  const { profession, companyName, cpfCnpj, address = {} } =
    Utils.getValue(client, 'creator') || {}
  const { street, zipCode, state, city } = address

  const TableLists = {
    constructions: (
      <ConstructionsTableList
        array={constructionList}
        updateList={updateList}
        managers={managersList}
        enterpriseTypes={enterpriseTypes}
      />
    ),
    managers: (
      <ManagersTableList
        array={managersList}
        updateList={updateList}
        creatorId={client.creator?.id}
      />
    )
  }

  return (
    <div className="ViewClient">
      <Axios
        run={true}
        api="enterprisetypes"
        onSuccess={onSuccessEnterpriseType}
      >
        <Axios run={run} api="clients" others={id} onSuccess={onSuccess}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <div>
                    <h4 className={classes.cardTitleWhite}>{companyName}</h4>
                    <p className={classes.cardCategoryWhite}>{cpfCnpj}</p>
                  </div>
                  <div className={classes.cardHeaderAction}>
                    {tableListToShow === 'constructions' ? (
                      <Button
                        color="success"
                        onClick={() => {
                          setTableListToShow('managers')
                        }}
                      >
                        CLIENTS_VIEW_CARD_HEADER_ACTION_SHOW_MANAGERS
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        onClick={() => {
                          setTableListToShow('constructions')
                        }}
                      >
                        CLIENTS_VIEW_CARD_HEADER_ACTION_SHOW_CONSTRUCTIONS
                      </Button>
                    )}
                    <Axios
                      api="clients"
                      others={`${id}/export`}
                      onSuccess={onSuccessExport}
                    >
                      {({ submit }) => (
                        <Button onClick={submit} color="success">
                          CLIENTS_VIEW_CARD_HEADER_ACTION_EXPORT
                        </Button>
                      )}
                    </Axios>
                  </div>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={4}>
                    <Grid item xs={11} sm={11} md={11}>
                      <div className="view_client_item">
                        <label>
                          <Translate>
                            CLIENTS_VIEW_INPUT_LABEL_MANAGER
                          </Translate>
                        </label>
                        <p>{client.name}</p>
                      </div>
                      <div className="view_client_item">
                        <label>
                          <Translate>CLIENTS_VIEW_INPUT_LABEL_EMAIL</Translate>
                        </label>
                        <p>{client.email}</p>
                      </div>
                      <div className="view_client_item">
                        <label>
                          <Translate>
                            CLIENTS_VIEW_INPUT_LABEL_PROFESSIONAL
                          </Translate>
                        </label>
                        <p>{profession}</p>
                      </div>
                      <div className="view_client_item">
                        <label>
                          <Translate>
                            CLIENTS_VIEW_INPUT_LABEL_ADDRESS
                          </Translate>
                        </label>
                        <p>{`${street} - ${zipCode}, ${city} - ${state}`}</p>
                      </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          onClick={() => {
                            setIsEditOpen(true)
                          }}
                          className="list-edit"
                        >
                          <Icon size={30} name="edit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12}>
                      {TableLists[tableListToShow]}
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Axios>
      </Axios>
      <Edit
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        item={client}
        updateClient={updateList}
      />
    </div>
  )
}