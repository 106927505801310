import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'

const FormGroupAbout = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik

  return (
    <div className="FormGroupAbout">
      <Grid item xs className="mg-auto">
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="blockTitle"
            onBlur={handleBlur}
            onChange={handleChange}
            label="ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TITLE"
            value={Utils.getValue(values, 'blockTitle')}
            error={Utils.getValue(errors, 'blockTitle')}
            helperText={Utils.getValue(errors, 'blockTitle')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="blockTextOne"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            className="text_ckeditor"
            label="ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_ONE"
            value={Utils.getValue(values, 'blockTextOne')}
            error={Utils.getValue(errors, 'blockTextOne')}
            helperText={Utils.getValue(errors, 'blockTextOne')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="blockTextTwo"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            className="text_ckeditor"
            label="ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_TWO"
            value={Utils.getValue(values, 'blockTextTwo')}
            error={Utils.getValue(errors, 'blockTextTwo')}
            helperText={Utils.getValue(errors, 'blockTextTwo')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="blockTextThree"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            className="text_ckeditor"
            label="ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_THREE"
            value={Utils.getValue(values, 'blockTextThree')}
            error={Utils.getValue(errors, 'blockTextThree')}
            helperText={Utils.getValue(errors, 'blockTextThree')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="blockTextFour"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            className="text_ckeditor"
            label="ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_FOUR"
            value={Utils.getValue(values, 'blockTextFour')}
            error={Utils.getValue(errors, 'blockTextFour')}
            helperText={Utils.getValue(errors, 'blockTextFour')}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default FormGroupAbout
