import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import AddEdidLogo from './components/AddEdidLogo'

import './styles.scss'

const Footer = () => {
  const [run, setRun] = useState(1) 
  const [item, setItem] = useState({})

  const onSuccess = ({ data }) => {
    setItem(data.rodape)
  }
  
  const updateList = () => {
    setRun(run + 1)
  }

  const tabs = [
    {
      icon: 'list',
      name: 'FOOTER_PAGE_TAB_ADD_LOGO',
      content: <AddEdidLogo item={item} updateList={updateList} />
    },
  ]
  
  return (
    <Axios run={run} api="pages.home" onSuccess={onSuccess}>
      <div className="Footer">
        <Tabs headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  )
}

export default Footer
