import React from 'react'

import Card from '@components/Card'

import FormGroupFooter from './components/FormGroupFooter'

import './styles.scss'

const AddEditLogo = props => {
  return (
    <div className="AddEditLogo">
     <Card>
        <FormGroupFooter {...props}/>
      </Card>
    </div>
  )
}

export default AddEditLogo
