import React from 'react'
import { Grid } from '@material-ui/core'
import Translate from '@components/Translate'

import Utils from '@utils'
import Input from '@components/Input'
import './styles.scss'
// import Stepper from '@components/Stepper'

// import Status from '@constants/status.constansts'

const ConfigComponent = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik

  return (
    <>
      <h2 className='calculator-title'><Translate>CALCULATOR_ADD_EDIT_LABEL</Translate></h2>
      <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              name="materialName"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_MATERIAL_NAME"
              value={Utils.getValue(values, 'materialName')}
              error={Utils.getValue(errors, 'materialName')}
              helperText={Utils.getValue(errors, 'materialName')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              {...props}
              name="conversionFactor"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_CONVERSION_FACTOR"
              value={Utils.getValue(values, 'conversionFactor')}
              error={Utils.getValue(errors, 'conversionFactor')}
              helperText={Utils.getValue(errors, 'conversionFactor')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              {...props}
              name="inputUnit"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_INPUT_UNIT"
              value={Utils.getValue(values, 'inputUnit')}
              error={Utils.getValue(errors, 'inputUnit')}
              helperText={Utils.getValue(errors, 'inputUnit')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              {...props}
              name="outputUnit"
              onBlur={handleBlur}
              onChange={handleChange}
              label="POST_ADD_EDIT_INPUT_LABEL_OUTPUT_UNIT"
              value={Utils.getValue(values, 'outputUnit')}
              error={Utils.getValue(errors, 'outputUnit')}
              helperText={Utils.getValue(errors, 'outputUnit')}
            />
          </Grid>
      </Grid>
    </>
  )
}

const FormGroup = props => {
  return <ConfigComponent {...props} />
}

export default FormGroup
