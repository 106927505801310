import clsx from 'clsx'
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { InputLabel, FormHelperText } from '@material-ui/core'

import './styles.scss'

const config = {
  height: 400,
  language: 'pt-br',
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'undo',
      'redo',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote'
    ],
    shouldNotGroupWhenFull: false
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Título 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Título 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Título 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Título 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Título 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Título 6',
        class: 'ck-heading_heading6'
      }
    ]
  }
}

const EditorComponent = props => {
  const { value, label, helperText, error, className } = props

  const handleChange = (e, editor) => {
    const { name } = props
    const html = editor.getData()
    const text = html.replace(/<\/?[^>]+(>|$)/g, "");
    console.log(text)
    console.log(html)
    if(text) {
      const event = {
        target: {
          name,
          value: text
        }
      }
      props.handleChange(event)
    }
  }

  const classNames = clsx(['CKEditorComponent', className], { error })
  return (
    <div className={classNames}>
      <InputLabel>{label}</InputLabel>
      <CKEditor
        data={value}
        config={config}
        editor={ClassicEditor}
        onBlur={handleChange}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </div>
  )
}

export default EditorComponent
