import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import Axios from '@components/Axios'
import Icon from '@components/Icon'
import Table from '@components/Table'

import './styles.scss'

const List = ({ list = [], setItem, updateList }) => {
  const columns = [
    { title: 'FAQ_LIST_TITLE', field: 'question' },
    {
      title: 'FAQ_LIST_OPTIONS',
      className: 'options',
      component: item => {
        return (
          <>
            <IconButton onClick={() => setItem(item)} className="list-edit">
              <Icon size={30} name="edit" />
            </IconButton>
            <Axios
              api="faqs"
              method="delete"
              others={item._id}
              onSuccess={updateList}
            >
              {({ submit }) => (
                <IconButton onClick={submit} className="list-delete">
                  <Icon size={30} name="delete_icon" />
                </IconButton>
              )}
            </Axios>
          </>
        )
      }
    }
  ]

  return (
    <div className="List">
      <Table data={list} columns={columns} title="FAQ_LIST_TABLE_TITLE" />
    </div>
  )
}

export default List

List.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
