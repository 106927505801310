import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import AddEditPrivacy from './components/AddEditPrivacy'

import './styles.scss'

const Privacy = () => {
  const [run, setRun] = useState(1)
  const [item, setItem] = useState({})

  const onSuccess = ({ data }) => {
    setItem(data)
  }

  const updateList = () => {
    setRun(run => run + 1)
  }

  const tabs = [
    {
      icon: 'add',
      name: 'PRIVACY_TAB_ADD',
      content: <AddEditPrivacy item={item} updateList={updateList} />
    }
  ]

  return (
    <Axios run={run} api="pages.privacy" onSuccess={onSuccess}>
      <div className="Privacy">
        <Tabs headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  )
}

export default Privacy
