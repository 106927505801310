import React, { useEffect, useState, useRef } from 'react'

import TextField from '@material-ui/core/TextField'
import { FormHelperText } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import clsx from 'clsx'
import Icon from '@components/Icon'
import Translate, { dict } from '@components/Translate'

import File from './components/File'
import Color from './components/Color'
import Number from './components/Number'
import Editor from './components/Editor'
import Select from './components/Select'
import Cropper from './components/Cropper'
import TextArea from './components/TextArea'
import Checkbox from './components/Checkbox'
import CKEditor from './components/CKEditor'
import Password from './components/Password'
import DatePicker from './components/DatePicker'
import Autocomplete from './components/Autocomplete'
import MultipleSelect from './components/MultipleSelect'

import './styles.scss'

const Input = props => {
  const inputRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputRef])

  const onChange = event => {
    const { positive } = props
    if (typeof props.onChange === 'function') {
      const { value, type } = event.target
      event.target = {
        ...props,
        ...event.target
      }
      if (type === 'number' && !isNaN(value)) {
        event.target.value = Number(value)
        if (positive && value < 0) {
          event.target.value = 0
        }
      }
      if (value === '') event.target.value = value
      props.onChange(event)
    }
  }

  const label = () => {
    const { label, type } = props
    if (label && type !== 'radio') {
      return <Translate>{String(label)}</Translate>
    } else {
      return null
    }
  }

  const inputProps = () => {
    let {
      readOnly,
      disabled,
      shrink,
      placeholder,
      icon,
      error,
      helperText,
      value = '',
      autoComplete = 'new-password'
    } = props

    const InputProps = { readOnly, disabled, autoComplete }

    const shrinkValue = shrink || open || value || value === 0
    const InputLabelProps = { shrink: shrinkValue }

    if (icon) {
      InputProps.endAdornment = <Icon name={icon} />
    }

    return {
      ...props,
      value,
      error,
      autoComplete,
      label: label(),
      variant: 'outlined',
      handleChange: onChange,
      InputProps: InputProps,
      InputLabelProps: InputLabelProps,
      helperText: dict.translate(helperText),
      placeholder: dict.translate(placeholder)
    }
  }

  const switchType = () => {
    let { type } = props

    switch (type) {
      case 'autocomplete':
        return <Autocomplete {...inputProps()} />
      case 'file':
        return <File {...inputProps()} />
      case 'select':
        return <Select {...inputProps()} />
      case 'date':
        return <DatePicker {...inputProps()} />
      case 'checkbox':
        return <Checkbox {...inputProps()} />
      case 'textarea':
        return <TextArea {...inputProps()} />
      case 'multipleSelect':
        return <MultipleSelect {...inputProps()} />
      case 'color':
        return <Color {...inputProps()} />
      case 'editor':
        return <Editor {...inputProps()} />
      case 'password':
        return <Password {...inputProps()} />
      case 'cropper':
        return <Cropper {...inputProps()} />
      case 'ckeditor':
        return <CKEditor {...inputProps()} />
      case 'number':
        return <Number {...inputProps()} />
      default:
        return (
          <TextField
            {...inputProps()}
            onChange={onChange}
            inputProps={{
              autocomplete: 'off'
            }}
          />
        )
    }
  }

  const onClick = () => {
    setOpen(true)
  }

  const { className, error, disabled, info, loading } = props

  let classes = clsx('Input', className, { error, disabled })

  return (
    <div ref={inputRef} onClick={onClick} className={classes}>
      <div className="info_input">
        <FormHelperText>
          <Translate>{info}</Translate>
        </FormHelperText>
      </div>
      {switchType()}
      {loading && (
        <div className="input__loading">
          <CircularProgress size={26} />
        </div>
      )}
    </div>
  )
}

Input.defaultProps = {
  onChange: () => true
}

export default Input
