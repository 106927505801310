import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor
} from "@js/material.js";

const cardIconStyle = {
  cardIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$secudaryCardHeader,&$roseCardHeader": {
      width: 60,
      padding: "8px",
      maxHeight: 60,
      marginTop: "-25px",
      borderRadius: "3px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: grayColor[0],
      '& svg': {
        margin: 'auto'
      }
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
};

export default cardIconStyle;
