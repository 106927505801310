import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'

import Card from '@components/Card'
import Axios from '@components/Axios'
import Button from '@components/Button'

import FormGroup from './components/FormGroup'

const AddEdit = props => {
  let { close, item, updateList } = props

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object().shape({
      summary: Yup.string().required('REQUIRED'),
      text: Yup.string().required('REQUIRED')
    })
  }

  const footer = submit => {
    return <Button onClick={submit}>HOME_ADD_CARD_SEND</Button>
  }

  const onSuccess = () => {
    close()
    updateList()
  }

  const add = ({ handleSubmit, ...formik }) => {
    return (
      <Card footer={() => footer(handleSubmit)}>
        <FormGroup {...props} formik={formik} />
      </Card>
    )
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values })
    resetForm()
  }

  return (
    <div className="AddEdit">
      <Axios
        method="put"
        api="admin.homepage"
        onSuccess={onSuccess}
      >
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
            {formik => add(formik)}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default AddEdit
