import React from 'react'

export default function Unity(props) {
  const { type } = props

  switch (type) {
    case 'tco2e':
      return (
        <>
          tCO<sub>2</sub>e
        </>
      )
    case 'tco2':
      return (
        <>
          tCO<sub>2</sub>
        </>
      )
    case 'co2e':
      return (
        <>
          CO<sub>2</sub>e
        </>
      )
    case 'co2':
      return (
        <>
          CO<sub>2</sub>
        </>
      )
    case 'MWh/m2':
      return (
        <>
          MWh/m<sup>2</sup>
        </>
      )
    case 'tco2e/m2':
      return (
        <>
          tCO<sub>2</sub>e/m<sup>2</sup>
        </>
      )
    default:
      return ''
  }
}
