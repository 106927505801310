import React from 'react'
import { Grid } from '@material-ui/core'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'

const FormGroup = props => {
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik
  return (
    <div className="FormGroup">
      <Grid container spacing={3}>
        <Grid item xs className="mg-auto">
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              name="author"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'author')}
              error={Utils.getValue(errors, 'author')}
              helperText={Utils.getValue(errors, 'author')}
              label="DEPOSITIONS_ADD_EDIT_INPUT_LABEL_AUTHOR"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              {...props}
              name="position"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'position')}
              error={Utils.getValue(errors, 'position')}
              helperText={Utils.getValue(errors, 'position')}
              label="DEPOSITIONS_ADD_EDIT_INPUT_LABEL_SUB_POSITION"
            />
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3} className="mg-auto">
          <Input
            {...props}
            name="photo"
            type="cropper"
            onBlur={handleBlur}
            aspectRatio={1 / 1}
            onChange={handleChange}
            className="form-group-cropper"
            value={Utils.getValue(values, 'photo')}
            error={Utils.getValue(errors, 'photo')}
            helperText={Utils.getValue(errors, 'photo')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="text"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            value={Utils.getValue(values, 'text')}
            error={Utils.getValue(errors, 'text')}
            helperText={Utils.getValue(errors, 'text')}
            label="DEPOSITIONS_ADD_EDIT_INPUT_LABEL_TEXT"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default FormGroup
