import { Extension, Notifications, NoteRounded, FreeBreakfast, People, Grain, Home, Info, TextFields, Security, Textsms, ViewDay } from "@material-ui/icons";

// core components/views for Admin layout
import Clients from "@pages/Clients";
import Faq from "@pages/Faq";
import Post from "@pages/Post";
import Terms from "@pages/Terms";
import Footer from "@pages/Footer";
import Privacy from "@pages/Privacy";
import Manager from "@pages/Manager";
import HomePage from "@pages/HomePage";
import Depostions from "@pages/Depostions";
import Feedbacks from "@pages/Feedbacks";
import AboutPage from "@pages/AboutPage";
import Inputs from "@pages/Inputs";
import Video from "@pages/Video";
import Download from "@pages/Download";
import Methodology from "@pages/Methodology";
import Capabilities from "@pages/Capabilities";
import Calculator from "@pages/Calculator";
import EnterpriseType from "@pages/EnterpriseType";
import Notification from "@pages/Notification";
import Fuels from "@pages/Fuels"

import ViewClient from "@pages/Clients/pages/View";

const dashboardRoutes = [
  {
    rule: 'CLIENTS',
    path: "/clients",
    component: Clients,
    layout: "/admin",
    name: "ROUTER_CLIENTS",
    icon: People,
    routes: [
      {
        params: '/:id',
        layout: "/admin",
        component: ViewClient,
        path: "/clients-view",
        name: "ROUTER_VIEW_CLIENTS",
      },
    ]
  },
  {
    rule: 'MANAGER',
    path: "/manager",
    component: Manager,
    layout: "/admin",
    name: "ROUTER_ADMIN",
    icon: FreeBreakfast,
  },
  {
    rule: 'INPUTS',
    path: "/inputs",
    component: Inputs,
    layout: "/admin",
    name: "ROUTER_INPUTS",
    icon: Grain,
  },
  {
    rule: 'INPUTS',
    path: "/fuels",
    component: Fuels,
    layout: "/admin",
    name: "ROUTER_FUELS",
    icon: Grain,
  },
  {
    rule: 'INPUTS',
    path: "/enterprisetypes",
    component: EnterpriseType,
    layout: "/admin",
    name: "ROUTER_ENTERPRISETYPES",
    icon: Grain,
  },
  {
    rule: 'HOMEPAGE',
    path: "/home",
    component: HomePage,
    layout: "/admin",
    name: "ROUTER_HOME_PAGE",
    icon: Home
  },
  {
    rule: 'DEPOSTIONS',
    path: "/depostions",
    component: Depostions,
    layout: "/admin",
    name: "ROUTER_DEPOSTIONS",
    icon: Notifications
  },
  {
    rule: 'DEPOSTIONS',
    path: "/feedbacks",
    component: Feedbacks,
    layout: "/admin",
    name: "ROUTER_FEEDBACKS",
    icon: NoteRounded
  },
  {
    rule: 'ABOUTPAGE',
    path: "/about",
    component: AboutPage,
    layout: "/admin",
    name: "ROUTER_ABOUT",
    icon: Info
  },
  {
    rule: 'TERMS',
    path: "/terms",
    component: Terms,
    layout: "/admin",
    name: "ROUTER_TERMS",
    icon: TextFields
  },
  {
    rule: 'PRIVACY',
    path: "/privacy",
    component: Privacy,
    layout: "/admin",
    name: "ROUTER_PRIVACY",
    icon: Security,
  },
  {
    rule: 'FAQ',
    path: "/faq",
    component: Faq,
    layout: "/admin",
    name: "ROUTER_FAQ",
    icon: Textsms,
  },
  {
    rule: 'POSTS',
    path: "/posts",
    component: Post,
    layout: "/admin",
    name: "ROUTER_POST",
    icon: Extension,
  },
  {
    rule: 'POSTS',
    path: "/videos",
    component: Video,
    layout: "/admin",
    name: "ROUTER_VIDEO",
    icon: Extension,
  },
  {
    rule: 'POSTS',
    path: "/downloads",
    component: Download,
    layout: "/admin",
    name: "ROUTER_DOWNLOAD",
    icon: Extension,
  },
  {
    rule: 'POSTS',
    path: "/methodologys",
    component: Methodology,
    layout: "/admin",
    name: "ROUTER_METHODOLOGY",
    icon: Extension,
  },
  {
    rule: 'POSTS',
    path: "/capabilities",
    component: Capabilities,
    layout: "/admin",
    name: "ROUTER_CAPABILITIES",
    icon: Extension,
  },
  {
    rule: 'POSTS',
    path: "/calculator",
    component: Calculator,
    layout: "/admin",
    name: "ROUTER_CALCULATOR",
    icon: Extension,
  },
  {
    rule: 'INPUTS',
    path: "/notification",
    component: Notification,
    layout: "/admin",
    name: "ROUTER_NOTIFICATION",
    icon: Notifications,
  },
  {
    rule: 'FOOTER',
    path: "/footer",
    component: Footer,
    layout: "/admin",
    name: "ROUTER_FOOTER",
    icon: ViewDay,
  },
];

export default dashboardRoutes;
