export default {
  LANG: 'pt-BR',

  ROUTER_PROFILE: 'Perfil',

  TABLE_ROWS_PER_PAGE: "Items por página",

  LOAD: 'Carregando',
  ADD_ITEM: 'Adicionar item',
  EMPTY_LIST: 'Lista vazia',
  BUTTON_CLOSE: 'Cancelar',
  BUTTON_SEND: 'Enviar',
  BUTTON_CONFIRM: 'Confirmar',

  REQUIRED: 'Item obrigatório',

  DELETE: 'Deletar',
  TABLE_SEARCH: 'Pesquisar',
  TABLE_SELECTED: 'Selecionado',

  USER_PENDING: 'Usuário Pendente',

  TOKEN_EXPIRED: 'Token expirado',
  INVALID_PARAMS: 'Parâmetros inválidos',
  INVALID_REQUEST: 'Pedido inválido',

  LOGIN_TITLE: 'Admin',
  LOGIN_BUTTON: 'Enviar',
  LOGIN_SUB_TITLE: 'Admin',
  LOGIN_FORM_INPUT_LABEL_USER: 'E-mail',
  LOGIN_FORM_INPUT_LABEL_PASSWORD: 'Senha',

  PROFILE_EDIT_SEND: 'Enviar',
  PROFILE_EDIT_ADD_CARD_TITLE: 'Perfil',

  PROFILE_TAB_EDIT: 'Perfil',
  PROFILE_TABS_TITLE: 'Perfil',

  ADMIN_NAV_BAR_LOGOUT: 'Logout',

  ROUTER_CLIENTS: 'Clientes',
  ROUTER_VIEW_CLIENTS: 'Clientes',
  ROUTER_ADMIN: 'Administradores',
  ROUTER_INPUTS: 'Insumos',
  ROUTER_FUELS: "List. Máquinas",
  ROUTER_HOME_PAGE: 'Home Page',
  ROUTER_DEPOSTIONS: 'Depoimentos',
  ROUTER_FEEDBACKS: 'Feedbacks',
  ROUTER_ABOUT: 'Sobre a CECarbon',
  ROUTER_TERMS: 'Termos de uso',
  ROUTER_PRIVACY: 'Política de privacidade',
  ROUTER_FAQ: 'FAQ',
  ROUTER_NOTIFICATION: 'Notificação',
  ROUTER_POST: 'Posts',
  ROUTER_VIDEO: 'Vídeos',
  ROUTER_FOOTER: 'Rodapé',
  ROUTER_METHODOLOGY: 'Metodologia',
  ROUTER_CAPABILITIES: 'Capacitação',
  ROUTER_CALCULATOR: 'Calculadora',

  VIDEO_TAB_LIST: 'Lista de Vídeos',
  VIDEO_TAB_ADD: 'Adicionar Vídeo',
  VIDEO_TAB_TITLE: 'Adicionar Vídeo',
  VIDEO_ADD_CARD_SEND: 'Publicar',
  VIDEO_STATUS_DRAFT: 'Rascunho',
  VIDEO_STATUS_PUBLISHED: 'Publicar',

  DOWNLOAD_TAB_LIST: 'Lista de Arquivos para Download',
  DOWNLOAD_TAB_ADD: 'Adicionar Arquivo para Download',

  DOWNLOAD_LIST_TABLE_TITLE: 'Lista de arquivos para Download',
  DOWNLOAD_LIST_TITLE: 'Título',
  DOWNLOAD_LIST_URL: 'Url do Download',
  DOWNLOAD_EDIT_MODAL_TILE: 'Editar Arquivo para Download',
  DOWNLOAD_ADD_EDIT_INPUT_LABEL_URL: 'Arquivo para Download',
  ROUTER_DOWNLOAD: 'Downloads',

  METHODOLOGY_TAB_LIST: 'Lista de Metodologias',
  METHODOLOGY_TAB_ADD: 'Adicionar Metodologias',

  METHODOLOGY_LIST_TABLE_TITLE: 'Lista de Metodologias',
  METHODOLOGY_LIST_TITLE: 'Título',
  METHODOLOGY_LIST_URL: 'Url da Metodologia',
  METHODOLOGY_LIST_DATE: 'Data da Metodologia',
  METHODOLOGY_EDIT_MODAL_TITLE: 'Editar Metodologia',
  METHODOLOGY_ADD_EDIT_INPUT_LABEL_URL: 'Metdologia',
  METHODOLOGY_ADD_EDIT_INPUT_LABEL_DATE: 'Data (formato: dd/mm/aaaa)',


  POST_TAB_LIST: 'Lista de Postagens',
  POST_TAB_ADD: 'Adicionar Post',
  POST_TAB_TITLE: 'Adicionar Postagem',
  POST_ADD_CARD_SEND: 'Publicar',
  POST_ADD_CARD_TITLE: 'Descreva sua postagem e publique',
  POST_STATUS_DRAFT: 'Rascunho',
  POST_STATUS_PUBLISHED: 'Publicar',

  CROPPER_SEND: 'Enviar Imagem',
  CROPPER_REMOVE: 'Remover Imagem',
  CROPPER_SELECT: 'Selecione uma imagem',

  POST_ADD_EDIT_INPUT_LABEL_TITLE: 'Título',
  POST_ADD_EDIT_INPUT_LABEL_IMAGE: 'Banner',
  POST_ADD_EDIT_INPUT_LABEL_AUTHOR: 'Autor',
  POST_ADD_EDIT_INPUT_LABEL_DESCRIPTION: 'Artigo',

  POST_ADD_EDIT_INPUT_LABEL_MATERIAL_NAME: 'Nome do Material',
  POST_ADD_EDIT_INPUT_LABEL_INPUT_UNIT: 'Unidade de entrada',
  POST_ADD_EDIT_INPUT_LABEL_OUTPUT_UNIT: 'Unidade de saída',
  POST_ADD_EDIT_INPUT_LABEL_CONVERSION_FACTOR: 'Fator de conversão',

  POST_ADD_EDIT_INPUT_LABEL_SUB_TITLE: 'SubTitle',
  POST_ADD_EDIT_INPUT_LABEL_SLUG: 'Slug',
  POST_ADD_EDIT_INPUT_LABEL_STATUS: 'Status',

  POST_LIST_TITLE: 'Título',
  POST_LIST_OPTIONS: 'Opções',
  POST_LIST_TABLE_TITLE: 'Lista de posts',
  POST_LIST_BANNER: 'Imagem',
  POST_LIST_AUTHOR: 'Autor',
  POST_LIST_PUBLISH: 'Publicado',
  POST_EDIT_MODAL_TILE: "Editar postagem",

  CAPABILITIES_EDIT_MODAL_TILE: "Editar capacitação",

  CAPABILITIES_ADD_EDIT_INPUT_LABEL_URL: 'Url do site externo',
  CAPABILITIES_LIST_URL: 'Url do site externo',
  CAPABILITIES_TAB_ADD: 'Adicionar Capacitação',
  CAPABILITIES_TAB_LIST: 'Lista de Capacitações',
  CAPABILITIES_LIST_TABLE_TITLE: 'LISTA DE CAPACITAÇÕES',

  CALCULATOR_LIST_MATERIAL_NAME_TITLE: 'Nome do material',
  CALCULATOR_LIST_INPUT_UNIT: 'Unidade de entrada',
  CALCULATOR_LIST_OUTPUT_UNIT: 'Unidade de saída',
  CALCULATOR_LIST_CONVERSION_FACTOR: 'Fator de conversão',

  CALCULATOR_EDIT_MODAL_TITLE: 'Editar material',
  CALCULATOR_TAB_LIST: 'Lista de Materiais',
  CALCULATOR_TAB_ADD: 'Adicionar Material',
  CALCULATOR_LIST_TABLE_TITLE: 'LISTA DE MATERIAIS',

  VIDEO_LIST_TITLE: 'Título',
  VIDEO_LIST_URL: 'Url do Vídeo',
  VIDEO_LIST_OPTIONS: 'Opções',
  VIDEO_LIST_TABLE_TITLE: 'Lista de vídeos',
  VIDEO_LIST_BANNER: 'Imagem',
  VIDEO_LIST_AUTHOR: 'Autor',
  VIDEO_EDIT_MODAL_TILE: "Editar vídeo",

  VIDEO_ADD_EDIT_INPUT_LABEL_URL: 'URL',
  VIDEO_STEPS_LABEL_CONFIG: 'Configure seu vídeo para torná-lo público',


  CALCULATOR_ADD_EDIT_LABEL: 'Adicione o material para a calculadora',
  CALCULATOR_ADD_MATERIAL_SEND: 'Salvar',

  POST_STEPS_LABEL_CONFIG: 'Configure sua postagem para torná-la pública',
  POST_STEPS_LABEL_CONTENT: 'Conteúdo',

  STEPPER_BACK: 'Voltar',
  STEPPER_NEXT: 'Próximo',
  STEPPER_FINISH: 'Concluir',

  PROFILE_TAB_LIST: 'Perfil',
  PROFILE_SEND: 'Atualizar Perfil',

  FAQ_TAB_LIST: 'Lista de perguntas',
  FAQ_TAB_ADD: 'Adicionar uma pergunta',

  FAQ_LIST_TITLE: 'Título',
  FAQ_LIST_OPTIONS: 'Opções',
  FAQ_LIST_TABLE_TITLE: 'Lista de perguntas',
  FAQ_ADD_EDIT_INPUT_LABEL_SUB_QUESTION: 'Pergunta',
  FAQ_ADD_EDIT_INPUT_LABEL_ANSWER: 'Resposta',
  FAQ_BUTTON_CLOSE: 'Fechar',
  FAQ_ADD_CARD_SEND: 'Enviar',
  FAQ_EDIT_MODAL_TILE: 'Editar Peguntas',

  NOTIFICATION_TAB: 'Visualizar Notificação',
  NOTIFICATION_TAB_EDIT: 'Editar a notificação',

  NOTIFICATION_LIST_TITLE: 'Título',
  NOTIFICATION_LIST_OPTIONS: 'Opções',
  NOTIFICATION_LIST_DATE: 'Data',
  NOTIFICATION_LIST_CONTENT: 'Conteúdo',
  NOTIFICATION_LIST_VISIBLE: 'Visível',
  NOTIFICATION_ADD_EDIT_INPUT_LABEL_CONTENT: 'Conteúdo',
  NOTIFICATION_ADD_EDIT_INPUT_LABEL_VISIBLE: 'Visível',
  NOTIFICATION_BUTTON_CLOSE: 'Fechar',
  NOTIFICATION_ADD_CARD_SEND: 'Enviar',
  NOTIFICATION_EDIT_MODAL_TILE: 'Editar Peguntas',

  HOME_ADD_CARD_SEND: "Enviar",
  HOME_ADD_EDIT_INPUT_LABEL_TITLE: 'Título',
  HOME_ADD_EDIT_INPUT_LABEL_TEXT: 'Descrição',
  HOME_ADD_EDIT_INPUT_LABEL_SUB_TITLE: 'Sub Titulo',
  HOME_ADD_EDIT_INPUT_LABEL_SUMMARY: 'Título',

  HOME_TAB_ADD_HOME: "Detalhes da Home",
  HOME_TAB_ADD_ABOUT: "Detalhes do Sobre",

  DEPOSITIONS_TAB_LIST: "Depoimentos",
  DEPOSITIONS_TAB_ADD: "Adicionar Depoimento",
  DEPOSITIONS_LIST_TABLE_HEADER_PHOTO: "Foto",
  DEPOSITIONS_LIST_TABLE_HEADER_AUTHOR: "Author",
  DEPOSITIONS_LIST_TABLE_HEADER_POSTION: "Posição",
  DEPOSITIONS_LIST_TABLE_HEADER_OPTIONS: "Opções",
  DEPOSITIONS_LIST_TABLE_TITLE: "Lista de Depoimentos",
  DEPOSITIONS_ADD_EDIT_INPUT_LABEL_AUTHOR: "Author",
  DEPOSITIONS_ADD_EDIT_INPUT_LABEL_SUB_POSITION: "Posição",
  DEPOSITIONS_ADD_EDIT_INPUT_LABEL_TEXT: "Texto",
  DEPOSITIONS_ADD_EDIT_INPUT_LABEL_BLOCKS: "Descrição",

  DEPOSITIONS_EDIT_MODAL_TILE: "Editar Depoimento",
  DEPOSITIONS_ADD_CARD_SEND: "Enviar",

  FEEDBACKS_TAB_LIST: "Feedbacks",
  FEEDBACKS_TAB_ADD: "Adicionar Feedback",
  FEEDBACKS_LIST_TABLE_HEADER_PHOTO: "Foto",
  FEEDBACKS_LIST_TABLE_HEADER_AUTHOR: "Author",
  FEEDBACKS_LIST_TABLE_HEADER_RESPONSIBILITY: "Cargo",
  FEEDBACKS_LIST_TABLE_HEADER_TEXT: "Texto do Depoimento",
  FEEDBACKS_LIST_TABLE_HEADER_POSTION: "Posição",
  FEEDBACKS_LIST_TABLE_HEADER_OPTIONS: "Opções",
  FEEDBACKS_LIST_TABLE_TITLE: "Lista de Feedbacks",
  FEEDBACKS_ADD_EDIT_INPUT_LABEL_AUTHOR: "Author",
  FEEDBACKS_ADD_EDIT_INPUT_LABEL_RESPONSIBILITY: "Cargo",
  FEEDBACKS_ADD_EDIT_INPUT_LABEL_SUB_POSITION: "Posição",
  FEEDBACKS_ADD_EDIT_INPUT_LABEL_TEXT: "Texto",
  FEEDBACKS_ADD_EDIT_INPUT_LABEL_BLOCKS: "Descrição",
  FEEDBACKS_BUTTON_CLOSE: 'Cancelar',

  DEPOSITIONS_BUTTON_CLOSE: 'Cancelar',

  FEEDBACKS_EDIT_MODAL_TILE: 'Editar Feedback',
  FEEDBACKS_ADD_CARD_SEND: 'Enviar',

  ABOUT_PAGE_TAB_ADD_SEEK: 'Sobre a ferramenta',
  ABOUT_PAGE_TAB_ADD_HOW_IT_WORKS: 'Como funciona a CECarbon',

  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_TITLE: 'Título',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_SUBTITLE: 'Sub Título',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_TEXT: 'O que buscamos',

  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TITLE: 'Título',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT: 'Descrição',

  ABOUT_PAGE_ADD_CARD_SEND: 'Enviar',

  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_ONE: 'Paragrafo 1',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_TWO: 'Paragrafo 2',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_THREE: 'Paragrafo 3',
  ABOUT_PAGE_ADD_EDIT_INPUT_LABEL_BLOCK_TEXT_FOUR: 'Paragrafo 4',

  TERMS_TAB_ADD: 'Adicionar Termos de uso',
  TERMS_ADD_EDIT_INPUT_LABEL_TITLE: 'Título',
  TERMS_ADD_EDIT_INPUT_LABEL_SUB_TITLE: 'Sub Título',
  TERMS_ADD_EDIT_INPUT_LABEL_TEXT: 'Descrição',
  TERMS_ADD_CARD_SEND: 'Enviar',

  PRIVACY_TAB_ADD: 'Adicionar Política de privacidade',
  PRIVACY_ADD_EDIT_INPUT_LABEL_TITLE: 'Título',
  PRIVACY_ADD_EDIT_INPUT_LABEL_TEXT: 'Descrição',
  PRIVACY_ADD_CARD_SEND: 'Enviar',

  MANAGER_TAB_LIST: 'Editar Administrador',
  MANAGER_TAB_ADD: 'Adicionar Administrador',
  MANAGER_ADD_EDIT_INPUT_LABEL_NAME: 'Nome',
  MANAGER_ADD_EDIT_INPUT_LABEL_EMAIL: 'Email',
  MANAGER_ADD_EDIT_INPUT_LABEL_PASSWORD: 'Senha',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_CLIENTS: 'Clientes',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_MANAGER: 'Administradores',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_INPUTS: 'Insumos',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_HOME_PAGE: 'Homepage',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_ABOUT_PAGE: 'Sobre a CECarbon',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_TERMS: 'Termos de uso',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_PRIVACY: 'Política de privacidade',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FAQ: 'FAQ',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_POSTS: 'Pots',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_VIDEOS: 'Vídeos',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_DOWNLOADS: 'Vídeos',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FOOTER: 'Rodapé',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_DEPOSTIONS: 'Depoimentos',
  MANAGER_ADD_EDIT_INPUT_LABEL_RULE_FEEDBACK: 'Feedback',
  MANAGER_EDIT_MODAL_TILE: 'Editar administrador',
  MANAGER_BUTTON_CLOSE: 'Fechar',
  MANAGER_ADD_CARD_SEND: 'Enviar',
  MANAGER_REMOVE_CARD_SEND: 'Remover',

  MANAGER_LIST_NAME: 'Nome',
  MANAGER_LIST_EMAIL: 'Email',
  MANAGER_LIST_OPTIONS: 'Opções',

  FOOTER_PAGE_TAB_ADD_LOGO: 'Logo do Rodapé',
  FOOTER_PAGE_TAB_ADD_METHODOLOGY: 'Metodologia',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_LOGO: 'Usuário CECarbon',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_LOGO_TWO:
    'Logo Execução + Realização',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_REALIZATION: 'Realização',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_ONE: 'Logo 1',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_TWO: 'Logo 2',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_THREE: 'Logo 3',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_FOUR: 'Logo 4',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_FIVE: 'Logo 5',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_LOGO_SIX: 'Logo 6',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_BUTTON_CHANGE: 'Alterar',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_BUTTON_VIEW: 'Visualizar',

  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_TITLE_METHODOLOGY: 'Enviar Metodologia',
  FOOTER_ADD_EDIT_LOGO_FORMGROUP_FOOTER_INPUT_LABEL_METHODOLOGY: 'Metodologia',

  CLIENTS_CARD_VIEW_ONE_TITLE: 'Cadastros de usuarios',
  CLIENTS_CARD_VIEW_TWO_TITLE: 'Obras reais',
  CLIENTS_CARD_VIEW_THREE_TITLE: 'Gestores',
  CLIENTS_CARD_VIEW_FOUR_TITLE: 'Cidade mais cadastrada',
  CLIENTS_CARD_VIEW_FIVE_TITLE: 'Emissão',
  CLIENTS_CARD_VIEW_SIX_TITLE: 'Consumo Energetico',

  CLIENTS_CARD_VIEW_ONE_DESCRIPTION: 'Total de cadastros',
  CLIENTS_CARD_VIEW_TWO_DESCRIPTION: 'Total de Obras',
  CLIENTS_CARD_VIEW_THREE_DESCRIPTION: 'Total de Gestores',
  CLIENTS_CARD_VIEW_FOUR_DESCRIPTION: 'Cidade mais cadastrada',
  CLIENTS_CARD_VIEW_FIVE_DESCRIPTION: 'Total de emissão',
  CLIENTS_CARD_VIEW_SIX_DESCRIPTION: 'Consumo energético',

  CLIENTS_CARD_HEADER_TABLE_LIST_TITLE: 'Cadastro',
  CLIENTS_CARD_HEADER_TABLE_LIST_DESCRIPTION: 'Lista de Clientes',
  CLIENTS_CARD_HEADER_ACTION_EXPORT: 'Exportar Clientes',
  CONSTRUCTIONS_CARD_HEADER_ACTION_EXPORT: 'Exportar Obras',

  CLIENTS_TABLE_ACTION_FILTER: 'Filtro',
  CLIENTS_LIST_TABLE_HEADER_NAME: 'Nome',
  CLIENTS_LIST_TABLE_HEADER_COMPANY_NAME: 'Empresa',
  CLIENTS_LIST_TABLE_HEADER_COMPANY_PROFILE: 'Tipo de empresa',
  CLIENTS_LIST_TABLE_HEADER_EMAIL: 'Email',
  CLIENTS_LIST_TABLE_HEADER_CITY: 'Cidade',
  CLIENTS_LIST_TABLE_HEADER_STATE: 'Estado',
  CLIENTS_LIST_TABLE_HEADER_TOTAL_EMISSION: 'Emissão C02',
  CLIENTS_LIST_TABLE_HEADER_TOTAL_ENERGY: 'Energia J',
  CLIENTS_LIST_OPTIONS: 'Opções',
  CLIENTS_LIST_TABLE_TITLE: 'Lista de Clientes',
  
  CLIENTS_OPEN_MODAL_DELETE_TITLE: 'Deletar usuário',
  CLIENTS_OPEN_MODAL_DELETE_SUB_TITLE: 'Essa ação irá apagar todos os registros como obra e inventários ligados a esse usuário e não poderá ser revertida. Deseja realmente deletar o usuário {{username}} ({{email}}) ?',
  CLIENTS_OPEN_MODAL_ACTION_DELETE: 'Deletar',

  CLIENTS_VIEW_CARD_HEADER_TABLE_LIST_TITLE: 'Obras',
  CLIENTS_VIEW_CARD_HEADER_TABLE_LIST_DESCRIPTION: 'Lista de Obras',

  CLIENTS_VIEW_LIST_TABLE_HEADER_NAME: 'Nome',
  CLIENTS_VIEW_LIST_TABLE_HEADER_COMPANY_NAME: 'Nome da empresa',
  CLIENTS_VIEW_LIST_TABLE_HEADER_TYPE_REGISTER: 'Obra Real',
  CLIENTS_VIEW_LIST_TABLE_HEADER_MANAGER: 'Gestor',
  CLIENTS_VIEW_LIST_TABLE_HEADER_STATUS: 'Status',
  CLIENTS_VIEW_LIST_TABLE_HEADER_ADDRESS: 'Endereço',
  CLIENTS_VIEW_LIST_TABLE_HEADER_TOTAL_EMISSION: 'Emissão C02',
  CLIENTS_VIEW_LIST_TABLE_HEADER_TOTAL_ENERGY: 'Energia J',
  CLIENTS_VIEW_LIST_OPTIONS: 'Opções',
  CLIENTS_VIEW_LIST_TABLE_TITLE: 'Lista de Obras',
  CLIENTS_VIEW_LIST_TABLE_OPTIONS_ACTION: 'Visualizar Relatório',

  CLIENTS_VIEW_MANAGERS_LIST_TABLE_TITLE: 'Lista de Gestores',
  CLIENTS_VIEW_MANAGERS_LIST_NAME_TITLE: 'Nome',
  CLIENTS_VIEW_MANAGERS_LIST_DEPARTMENT_TITLE: 'Departamento',
  CLIENTS_VIEW_MANAGERS_LIST_PHONE_TITLE: 'Telefone',
  CLIENTS_VIEW_MANAGERS_LIST_POSITION_TITLE: 'Posição',
  CLIENTS_VIEW_MANAGERS_LIST_CONSTRUCTIONS_NUMBER: 'Obras associadas',
  CLIENTS_VIEW_MANAGERS_LIST_REGISTRATION_TYPE_TITLE: 'Tipo do registro',
  CLIENTS_VIEW_MANAGERS_LIST_REGISTRATION_NUMBER_TITLE: 'Número do registro',
  CLIENTS_VIEW_MANAGERS_LIST_OPTIONS_TITLE: 'Número do registro',

  CLIENTS_EDIT_MANAGER_MODAL_TILE: 'Editar dados do gestor',
  CLIENTS_ADD_MANAGER_MODAL_TILE: 'Adicionar gestor',

  CLIENTS_REMOVE_MANAGER_MODAL_TILE: 'Remover gestor',

  CLIENTS_REMOVE_MANAGER_MODAL_CONSTRUCTIONS:
    'O gestor está associado a {{constructions}} obras. Ao remover o gestor, as obras associadas ficarão sem gestor administrativo. ',
  CLIENTS_REMOVE_MANAGER_MODAL_TEXT: 'Tem certeza que deseja remover o gestor?',

  CLIENTS_EDIT_MANAGER_INPUT_LABEL_NAME: 'Nome',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_EMAIL: 'Email',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_PASSWORD: 'Nova senha',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_PHONE: 'Telefone',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_POSITION: 'Posição',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_DEPARTMENT: 'Departamento',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_REGISTRATION_TYPE: 'Tipo do registro',
  CLIENTS_EDIT_MANAGER_INPUT_LABEL_REGISTRATION_NUMBER: 'Número do registro',

  CLIENTS_VIEW_CARD_HEADER_ACTION_EXPORT: 'Exportar Obras',
  CLIENTS_VIEW_CARD_HEADER_ACTION_SHOW_MANAGERS: 'Mostrar Gestores',
  CLIENTS_VIEW_CARD_HEADER_ACTION_SHOW_CONSTRUCTIONS: 'Mostrar Obras',

  CLIENTS_VIEW_ADD_MANAGER: 'Adicionar Gestor',

  CLIENTS_VIEW_INPUT_LABEL_MANAGER: 'Gestor Administrativo',
  CLIENTS_VIEW_INPUT_LABEL_EMAIL: 'Email',
  CLIENTS_VIEW_INPUT_LABEL_PROFESSIONAL: 'Profissão',
  CLIENTS_VIEW_INPUT_LABEL_ADDRESS: 'Endereço',

  CLIENTS_EDIT_MODAL_TILE: 'Editar dados do cliente',
  CLIENTS_EDIT_INPUT_LABEL_MANAGER: 'Gestor Administrativo',
  CLIENTS_EDIT_INPUT_LABEL_EMAIL: 'Email',
  CLIENTS_EDIT_INPUT_LABEL_COMPANY_NAME: 'Empresa',
  CLIENTS_EDIT_INPUT_LABEL_CPF_CNPJ: 'CPF/CNPJ',
  CLIENTS_EDIT_INPUT_LABEL_PROFESSION: 'Profissão',
  CLIENTS_EDIT_INPUT_LABEL_ZIP_CODE: 'Cep',
  CLIENTS_EDIT_INPUT_LABEL_STREET: 'Rua',
  CLIENTS_EDIT_INPUT_LABEL_CITY: 'Cidade',
  CLIENTS_EDIT_INPUT_LABEL_STATE: 'Estado',
  CLIENTS_EDIT_INPUT_LABEL_COMPLEMENT: 'Complemento',

  CONSTRUCTIONS_EDIT_MODAL_TITLE: 'Editar dados da obra',
  CONSTRUCTIONS_EDIT_SUBMIT: 'Salvar',

  CONSTRUCTIONS_EDIT_INPUT_LABEL_NAME: 'Nome',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_COMPANY_NAME: 'Empresa',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_LAND_AREA: 'Área do Terreno (m²) ',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_BUILD_AREA:
    'Área Total a Construir - Prefeitura (m²)',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_AREA_DESTROY: 'Área Total a Demolir (m²)',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_INITIAL_COST: 'Custo inicial estimado (R$)',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_FINAL_COST: 'Custo final apurado (R$)',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_START_DATA: 'Data de ínício da construção',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_END_DATA: 'Data de término da construção',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_CONSTRUCTION_SYSTEM: 'Sistema construtivo',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_DEVELOPMENT: 'Tipo de empreendimento',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_INVESTMENT: 'Tipo de Investimento',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_DEVELOPMENT_CATEGORY:
    'Categoria de empreendimento',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_DEVELOPMENT_OTHER: 'Outros',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_ENTERPRISE_STANDARD:
    'Padrão de Empreendimento',
  CONSTRUCTIONS_EDIT_INPUT_HELP_ENTERPRISE_STANDARD:
    'Neste item deve-se estabelecer o padrão do seu empreendimento, como Padrão Popular, Baixo, Médio, Alto ou de Interesse Social”. Esta classificação é descrita conforme a ABNT - NBR 12721:2006, especificada no item 8.2 e Tabela 1',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_ENTERPRISE_STAGE: 'Etapa do projeto',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_TYPE_REGISTER: 'Tipo de Registro', 
  CONSTRUCTIONS_EDIT_INPUT_LABEL_PUBLIC_FUNDING: 'Tem financiamento público?',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_PUBLIC_CERTIFICATION:
    'Vai buscar ou obteve certificação?',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_INFORM_CERTIFICATION: 'Informar Certificação',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_STATUS: 'Status',
  CONSTRUCTIONS_EDIT_INPUT_LABEL_MANAGER: 'Gestor',

  CLIENTS_VIEW_CARD_HEADER_INPUT_LABEL_PASSWORD: 'Nova Senha',
  CLIENTS_VIEW_CARD_HEADER_BUTTON_CHANGE_PASSWORD: 'Redifinir Senha',

  HEADER_RESULTS_TITLE: 'CECarbon - Relatório da Obra',
  HEADER_RESULTS_DESCRIPTION_ONE:
    'Este relatório foi gerado na ferramenta CECarbon - Calculadora de Consumo Energético e Emissões de Carbono para Edificações. A ferramenta calcula os indicadores de emissões de gases de efeito estufa (GEE), em tCO2e e consumo energético (ou energia embutida) em MJ e MWh, considerando os principais insumos da construção civil, até o momento da entrega da obra.  Os resultados detalhados apresentados a seguir separam as informações por etapa da obra, categoria escopo e fonte individual.',
  HEADER_RESULTS_DESCRIPTION_TWO:
    'O relatório tem respaldo em métodos científicos internacionais, com a maioria da base de dados regionalizada. Os métodos podem ser acessados no Relatório Metodológico disponível na ferramenta.',
  ADMIN_RESULTS_VIEW_RESULT: 'Ver resultados por etapa da obra',
  ADMIN_RESULTS_DOWNLOAD: 'Fazer download do relatório',

  ADMIN_REPORT_ABSOLUTE_RESULTS_TITLE: 'Resultados absolutos',

  ADMIN_RESULTS_REPORTS_HEADER_DESCRIPTION: "Este relatorio foi emitido em {{date}}. Os presentes valores são validos para o momento da realização do relatório, conforme atualizações do sistema.",
  ADMIN_RESULTS_REPORTS_TITLE: '{{name}} - {{status}}ª Edição',
  ADMIN_RESULTS_REPORTS_ITEM_COMPANY: 'Razão social',
  ADMIN_RESULTS_REPORTS_ITEM_CPNJ: 'CNPJ',
  ADMIN_RESULTS_REPORTS_ITEM_PROFILE: 'Perfil da empresa',
  ADMIN_RESULTS_REPORTS_ITEM_COUNTRY: 'Município',
  ADMIN_RESULTS_REPORTS_ACTION: 'Baixar relatório',

  ADMIN_RESULTS_ABSOLUTE_RESULTS_TITLE: 'Resultados absolutos',
  ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_ONE_TITLE: 'Emissões totais da obra:',
  ADMIN_RESULTS_ABSOLUTE_RESULTS_TICKET_TWO_TITLE:
    'Consumo energético total da obra:',

  ADMIN_REPORT_STEP_WORK_TITLE: 'Resultados por etapa da obra',
  ADMIN_REPORT_STEP_WORK_CARD_ONE_TITLE: 'Etapa com maior emissão de GEE:',
  ADMIN_REPORT_STEP_WORK_CARD_TWO_TITLE: 'Etapa com maior consumo energético:',

  RESULT_BY_CATEGORY_TITLE: 'Resultados por Categoria',

  ADMIN_RESULTS_NOTICE_ONE_ICON: 'árvores',
  ADMIN_RESULTS_NOTICE_ONE_DESCRIPTION:
    'As emissões totais do seu inventário equivalem ao conteúdo de carbono armazenado no seguinte número de árvores típicas da Mata Atlântica.',

  ADMIN_RESULTS_NOTICE_TWO_ICON: 'casas',
  ADMIN_RESULTS_NOTICE_TWO_DESCRIPTION:
    'A energia incorporada em todos os insumos utilizados na sua obra seria capaz de abastecer o seguinte número de família médias brasileiras, com 4 pessoas, anualmente.',

  INDICATORS: 'Indicadores',
  INDICATORS_LABEL_ONE: 'Emissão (kgCO<sub>2</sub>) /m² construído',
  INDICATORS_LABEL_TWO: 'Emissão (kgCO<sub>2</sub>)/R$ gasto',
  INDICATORS_LABEL_FOUR: 'Energia (MJ)/m² construído',
  INDICATORS_LABEL_FIVE: 'Energia (MJ)/R$ gasto',

  ACTIONS_TITLE: 'Ações de Redução, Compensação ou Mitigação',
  ACTIONS_HEADER_ONE: 'Tipo de ação',
  ACTIONS_HEADER_TWO: 'Descrição da Ação',
  ACTIONS_HEADER_THREE: 'Resultado Estimado',
  ACTIONS_HEADER_FOUR: 'Unidade',

  TABLE_RESULT_HEADER_ONE: 'Fonte',
  TABLE_RESULT_HEADER_TWO: 'Emissões de GEE (tCO2e)',
  TABLE_RESULT_HEADER_THREE: 'Emissões biogênicas (tCO2)',
  TABLE_RESULT_HEADER_FOUR: 'Consumo Energético (MJ)',

  RESULT_BY_SCOPE_HEADER_ONE: 'Emissões de GEE (tCO2e)',

  RESULT_BY_SOURCES_TITLE: 'Resultado por Fonte Individual',
  RESULT_BY_SOURCES_HEADER_ONE: 'Fonte',
  RESULT_BY_SOURCES_HEADER_TWO: 'Dados de atividade',
  RESULT_BY_SOURCES_HEADER_THREE: 'Unidade do dado de atividade',
  RESULT_BY_SOURCES_HEADER_FOUR: 'Fator de emissão de GEE (tCO2e/unidade)',
  RESULT_BY_SOURCES_HEADER_FIVE:
    'Fator de emissão de Biogênico (tCO2e/unidade)',
  RESULT_BY_SOURCES_HEADER_SIX: 'Emissões de CO2 biogênico (t)',
  RESULT_BY_SOURCES_HEADER_SEVEM: 'Emissões biogênicas (tCO2)',
  RESULT_BY_SOURCES_HEADER_EIGHT: 'Fator de consumo energéticos (MJ/unidade)',
  RESULT_BY_SOURCES_HEADER_NINE: 'Consumo Energético (MJ)',

  RESULT_BY_SCOPE_TITLE: 'Resultados por Escopo (somente emissões)',
  RESULT_BY_SCOPE_CHART_ONE: 'Resultados das emissões em',
  RESULT_BY_SCOPE_CHART_TWO: 'Emissões biogênicas',

  SCOPE_TICKET_LABEL_ONE: 'Fonte com maior emissão de GEE:',
  SCOPE_TICKET_LABEL_TWO: 'Fonte com maior consumo energético:',
  SCOPE_TICKET_LABEL_THREE: 'Maior emissão',
  SCOPE_TICKET_LABEL_FOUR: 'Maior consumo',

  ADMIN_RESULTS_REPORTS_DESCRIPTION:
    '<p>O SindusCon-SP, a GIZ e a SNH não se responsabilizam pela veracidade dos dados informados.</p> <p>O SindusCon-SP, a GIZ e a SNH não se responsabilizam por eventuais erros ou distorções apresentadas no cálculo, sob nenhum aspecto, uma vez que a ferramenta é consultiva.</p> <p>Valores zerados para fatores de emissão significam que estas emissões não ocorreem para a fonte listata.</p> <p>Os resultafos do consumo de frete e combustível são itens consolidados a partir dos inputs de cada etapa.</p>',

  VIEW_CLIENT_REPORT_BUTTON_CLOSE: 'Fechar',
  VIEW_CLIENT_REPORT_TITLE: 'Relátorio',

  INPUTS_LIST_CO2: 'kgCO2',
  INPUTS_LIST_N2O: 'kgNO2',
  INPUTS_LIST_CH4: 'kgCH4',
  INPUTS_LIST_PHASES: 'Fases',
  INPUTS_LIST_UNITY: 'Unidade',
  INPUTS_LIST_ENERGY: 'MJ Energia',
  INPUTS_LIST_AVERAGE: 'Consumo Médio (L/h)',
  INPUTS_LIST_OPTIONS: 'Opções',
  INPUTS_LIST_CATEGORY: 'Categoria',
  INPUTS_LIST_DESCRIPION: 'Descrição',
  INPUTS_LIST_CO2_BIOGENIC: 'kgCO2 Biogênico',

  INPUTS_LIST_TABLE_TITLE: 'Lista de Inputs',

  INPUTS_ADD_INPUT_LABEL_CH4: 'kgCH4',
  INPUTS_ADD_INPUT_LABEL_YEAR: 'Ano',
  INPUTS_ADD_INPUT_LABEL_YEAR_INFO: 'Informe o ano dos valores a seguir',
  INPUTS_ADD_INPUT_LABEL_CO2: 'kgCO2',
  INPUTS_ADD_INPUT_LABEL_N2O: 'kgN2O',
  INPUTS_ADD_INPUT_LABEL_UNIT: 'Unidade',
  INPUTS_ADD_INPUT_LABEL_VISIBLED: 'Omitir',
  INPUTS_ADD_INPUT_LABEL_SUPPLIER: 'Fornecedor',
  INPUTS_ADD_INPUT_LABEL_PHASES: 'Fases',
  INPUTS_ADD_INPUT_LABEL_ENERGY: 'MJ Energia',
  INPUTS_ADD_INPUT_LABEL_SUBTYPE: 'Subtipo',
  INPUTS_ADD_INPUT_LABEL_USERS: 'Usuários',
  INPUTS_ADD_INPUT_LABEL_CATEGORY: 'Categoria',
  INPUTS_ADD_INPUT_LABEL_SUB_DESCRIPTION: 'Descrição',
  INPUTS_ADD_INPUT_LABEL_CO2BIOGENIC: 'kgCO2 Biogênico',
  INPUTS_ADD_INPUT_LABEL_CO2_INFO: 'Caso o fator de emissão já esteja consolidado em tCO2e, inserir abaixo.',

  INPUT_BUTTON_DELETE_YEAR: "Excluir ano",
  INPUT_BUTTON_ADD_YEAR: "Adicionar ano",
  INPUT_BUTTON_CLOSE: "Fechar",
  INPUT_ADD_CARD_SEND: 'Salvar',
  INPUT_EDIT_MODAL_TILE: 'Editar Insumo',

  INPUT_TAB_LIST: 'Lista de Insumos',
  INPUT_TAB_ADD_EDIT: 'Adicionar Material',

  FUELS_LIST_TABLE_TITLE: 'Lista de Máquinas e Equipamentos',
  FUELS_LIST_DESCRIPTION_MACHINE: 'Tipo de máquina',
  FUELS_LIST_TYPE_COMBUSTION: 'Tipo de combustão',
  FUELS_LIST_USED_FUEL: 'Combustível Utilizado',

  FUELS_INPUT_DESCRIPTION_MACHINE: 'Tipo de máquina',
  FUELS_INPUT_AVERAGE_CONSUMPTION: 'Consumo médio',
  FUELS_INPUT_TYPE_COMBUSTION: 'Tipo de combustão',
  FUELS_INPUT_USED_FUEL: 'Combustível Utilizado',

  ENTERPRISETYPE_BUTTON_CLOSE: 'Fechar',
  ENTERPRISETYPE_ADD_CARD_SEND: 'Enviar',
  ENTERPRISETYPE_ADD_INPUT_LABEL_SUB_NAME: 'Nome',
  ENTERPRISETYPE_EDIT_MODAL_TILE: 'Editar Tipo de Empreendimento',

  ENTERPRISETYPE_TAB_LIST: 'Lista de Tipos de Empreendimento',
  ENTERPRISETYPE_TAB_ADD_EDIT: 'Adicionar Tipo de empreendimento',

  ENTERPRISETYPES_ADD_ENTERPRISETYPE_LABEL_SUB_NAME: 'Name',

  ENTERPRISETYPES_LIST_TABLE_TITLE: 'Lista de Tipos de Empreendimento',

  ENTERPRISETYPE_LIST_NAME: 'Name',

  INPUTS_LIST_TABLE_TITLE: 'Lista de Inputs',

  ROUTER_ENTERPRISETYPES: 'Empreendimentos'
}
