import React from 'react'
// import clsx from 'clsx'
import PropTypes from 'prop-types'

// material-ui components
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

// core components
import Icon from '@components/Icon'
import Card from '@components/CardFull'
import CardBody from '@components/CardFull/CardBody.js'
import CardHeader from '@components/CardFull/CardHeader.js'

import { dict } from '@components/Translate'

import styles from '@js/material/components/customTabsStyle.js'

const useStyles = makeStyles(styles)

export default function CustomTabs(props) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, value) => {
    setValue(value)
  }

  const classes = useStyles()
  const { headerColor, plainTabs, tabs } = props

  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone
          }}
        >
          {tabs.map((prop, key) => {
            var icon = {}
            if (prop.icon) {
              icon = {
                icon: <Icon name={prop.icon} />
              }
            }
            return (
              prop.show !== false && (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper
                  }}
                  key={key}
                  label={dict.translate(prop.name)}
                  {...icon}
                ></Tab>
              )
            )
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          return (
            <div key={key} className={key === value ? 'show' : 'hide'}>
              {prop.content}
            </div>
          )
        })}
      </CardBody>
    </Card>
  )
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose'
  ]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      con: PropTypes.object,
      content: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
}
