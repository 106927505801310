import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Config from '@configs'
import Input from '@components/Input'
import Axios from '@components/Axios'
import Button from '@components/Button'
import Translate from '@components/Translate'

import * as Yup from 'yup'
import { Formik } from 'formik'

import { ReactComponent as Icon } from '@images/icon/icon.svg'
import { ReactComponent as DevShare } from '@images/logo-name-white.svg'

import './styles.scss'

const Login = () => {
  const history = useHistory()

  useEffect(() => {
    sessionStorage.clear()
  }, [])

  const schema = {
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('REQUIRED')
        .email(),
      password: Yup.string().required('REQUIRED')
    })
  }

  const onSuccess = ({ data }) => {
    console.log(data)

    setToken(data)
    history.push('/admin/clients')
  }

  const setToken = token => {
    sessionStorage.setItem(Config.TokenSession, token)
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values })
    resetForm()
  }

  return (
    <div className="Login">
      <div className="wrapper">
        <div className="column">
          <DevShare />
          <span>
            Cecarbon &copy; {1900 + new Date().getYear()} Sinduscon-Sp. Todos os
            direito reservados.
          </span>
        </div>
        <div className="column icon">
          <Icon />
        </div>
      </div>
      <div className="card">
        <div className="card_header">
          <p className="title">
            <Translate>Login</Translate>
          </p>
        </div>
        <div className="card_content">
          <Axios api="login" method="post" onSuccess={onSuccess}>
            {({ submit }) => (
              <Formik
                {...schema}
                onSubmit={(values, event) =>
                  onSubmit({ ...event, values, submit })
                }
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <>
                    <div className="form">
                      <Input
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        label="LOGIN_FORM_INPUT_LABEL_USER"
                        error={errors.email}
                        helperText={errors.email}
                      />
                      <Input
                        className="input_pass"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        label="LOGIN_FORM_INPUT_LABEL_PASSWORD"
                        error={errors.password}
                        helperText={errors.password}
                      />
                    </div>

                    <Button
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="default flex"
                    >
                      <Translate html>LOGIN_BUTTON</Translate>
                    </Button>
                  </>
                )}
              </Formik>
            )}
          </Axios>
        </div>
      </div>
    </div>
  )
}

export default Login
