import React, { useState } from 'react'
import Utils from '@utils'

import Input from '@components/Input'
import { Grid } from '@material-ui/core'
import Axios from 'axios'
// import './styles.scss'

const UF_STATES_MAP = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins'
}

const formatZipCode = zipCode => {
  const zipCodeNumbers = zipCode.replace(/\D/g, '')
  const zipCodeFormatted = zipCodeNumbers.replace(/(\d{5})(\d{3})/, '$1-$2')
  return zipCodeFormatted
}

const AddressEditor = ({ formik, addressObjectPath = 'address' }) => {
  const { handleBlur, handleChange, values, errors, setFieldValue } = formik
  const [loading, setLoading] = useState(false)

  const getAddress = async zipCode => {
    setLoading(true)
    try {
      const response = await Axios.get(
        `https://brasilapi.com.br/api/cep/v2/${zipCode}`
      )

      const { data } = response

      setFieldValue(`${addressObjectPath}.zipCode`, formatZipCode(data.cep))
      setFieldValue(`${addressObjectPath}.street`, data.street)
      setFieldValue(`${addressObjectPath}.city`, data.city)
      setFieldValue(`${addressObjectPath}.state`, UF_STATES_MAP[data.state])

      console.log(data)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const onZipCodeChange = e => {
    const zipCode = e.target.value

    setFieldValue(`${addressObjectPath}.zipCode`, zipCode)

    const zipCodeNumbers = zipCode.replace(/\D/g, ``)

    if (zipCodeNumbers.length === 8) {
      getAddress(zipCodeNumbers)
    }
  }

  return (
    <>
      <Grid item xs={4} sm={4}>
        <Input
          name={`${addressObjectPath}.zipCode`}
          onBlur={handleBlur}
          onChange={onZipCodeChange}
          label="CLIENTS_EDIT_INPUT_LABEL_ZIP_CODE"
          value={Utils.getValue(values, `${addressObjectPath}.zipCode`)}
          error={Utils.getValue(errors, `${addressObjectPath}.zipCode`)}
          helperText={Utils.getValue(errors, `${addressObjectPath}.zipCode`)}
          loading={loading}
        />
      </Grid>
      <Grid item>
        <Input
          name={`${addressObjectPath}.street`}
          onBlur={handleBlur}
          onChange={handleChange}
          label="CLIENTS_EDIT_INPUT_LABEL_STREET"
          value={Utils.getValue(values, `${addressObjectPath}.street`)}
          error={Utils.getValue(errors, `${addressObjectPath}.street`)}
          helperText={Utils.getValue(errors, `${addressObjectPath}.street`)}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <Input
            name={`${addressObjectPath}.city`}
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_CITY"
            value={Utils.getValue(values, `${addressObjectPath}.city`)}
            error={Utils.getValue(errors, `${addressObjectPath}.city`)}
            helperText={Utils.getValue(errors, `${addressObjectPath}.city`)}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Input
            name={`${addressObjectPath}.state`}
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_STATE"
            value={Utils.getValue(values, `${addressObjectPath}.state`)}
            error={Utils.getValue(errors, `${addressObjectPath}.state`)}
            helperText={Utils.getValue(errors, `${addressObjectPath}.state`)}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Input
            name={`${addressObjectPath}.complement`}
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_COMPLEMENT"
            value={Utils.getValue(values, `${addressObjectPath}.complement`)}
            error={Utils.getValue(errors, `${addressObjectPath}.complement`)}
            helperText={Utils.getValue(
              errors,
              `${addressObjectPath}.complement`
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AddressEditor
