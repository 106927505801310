import React, { useState, useEffect } from 'react';
import Tabs from '@components/Tabs';
import Axios from '@components/Axios';
import List from './components/List';
import AddEdit from './components/AddEdit';
import './styles.scss';

const Notification = () => {
  const [run, setRun] = useState(1);
  const [list, setList] = useState([]);
  const [item, setItem] = useState({});

  const onSuccess = ({ data }) => {
    setItem(data[0]);
    setList([...data]);
  };

  const updateList = () => {
    setRun(run + 1);
  };

  const tabs = [
    {
      icon: 'list',
      name: 'NOTIFICATION_TAB',
      content: <List list={list} setItem={setItem} updateList={updateList} />
    },
   {
      icon: 'add',
      name: 'NOTIFICATION_TAB_EDIT',
      content: (
        <AddEdit item={item} updateList={updateList} close={() => setItem({})} />
      )
    }
  ].filter(Boolean);

  return (
    <Axios run={run} api="notification" onSuccess={onSuccess}>
      <div className="Notification">
        <Tabs headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  );
};

export default Notification;