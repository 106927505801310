import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import AddEditSeek from './components/AddEditSeek'
import AddEditHowItWorks from './components/AddEditHowItWorks'

import './styles.scss'

const AboutPage = () => {
  const [run, setRun] = useState(1) 
  const [item, setItem] = useState({})

  const onSuccess = ({ data }) => {
    setItem(data.aboutpage)
  }

  const updateList = () => {
    setRun(run + 1)
  }

  const tabs = [
    {
      icon: 'add',
      name: 'ABOUT_PAGE_TAB_ADD_SEEK',
      content: <AddEditSeek item={item} updateList={updateList} />
    },
    {
      icon: 'add',
      name: 'ABOUT_PAGE_TAB_ADD_HOW_IT_WORKS',
      content: <AddEditHowItWorks item={item} updateList={updateList} />
    },
  ]

  return (
    <Axios run={run} api="pages.about" onSuccess={onSuccess}>
      <div className="Home">
        <Tabs headerColor="primary" tabs={tabs} />
      </div>
    </Axios>
  )
}

export default AboutPage
