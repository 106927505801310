import React from 'react'
import clsx from 'clsx'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

// import Button from '@components/Button'

import Translate from '@components/Translate'
import * as Material from '@material-ui/core/'

import './styles.scss'

const Stepper = props => {
  const { steps, orientation, expanded } = props
  const vertical = orientation === 'vertical'

  const [activeStep, setActiveStep] = React.useState(0)

  return (
    <div className={clsx(['Stepper'], { vertical })}>
      <Material.Stepper
        alternativeLabel
        activeStep={activeStep}
        orientation={orientation}
      >
        {steps.map(({ label }, index) => (
          <Step
            key={index}
            expanded={expanded}
            onClick={() => setActiveStep(index)}
          >
            <StepLabel>
              <Translate>{label}</Translate>
            </StepLabel>
          </Step>
        ))}
      </Material.Stepper>
      <div className="stepper-main">{steps[activeStep].component(props)}</div>
    </div>
  )
}

export default Stepper
