import React from 'react'

import Axios from '@components/Axios'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Translate from '@components/Translate'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Formik } from 'formik'

const DeleteManager = ({ onClose, manager, updateList, isOpen }) => {
  const managerConstructionsNumber = manager?.constructions?.length

  const onSuccess = () => {
    onClose()
    updateList()
  }

  const Actions = ({ submit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => onClose()}>
          MANAGER_BUTTON_CLOSE
        </Button>
        <Button
          onClick={() => {
            console.log('aloo')
            submit()
          }}
        >
          MANAGER_REMOVE_CARD_SEND
        </Button>
      </div>
    )
  }

  return (
    <div className="Edit">
      <Axios
        api="managers"
        method="delete"
        others={manager?.id}
        onSuccess={onSuccess}
      >
        {({ submit }) => (
          <Modal
            open={isOpen}
            close={onClose}
            title="CLIENTS_REMOVE_MANAGER_MODAL_TILE"
            actions={<Actions submit={submit} />}
          >
            <Typography>
              <Translate>CLIENTS_REMOVE_MANAGER_MODAL_TEXT</Translate>
            </Typography>
            {managerConstructionsNumber > 0 && (
              <Box mt={4}>
                <Alert severity="warning">
                  <Typography>
                    <Translate
                      parameters={{
                        constructions: managerConstructionsNumber
                      }}
                    >
                      CLIENTS_REMOVE_MANAGER_MODAL_CONSTRUCTIONS
                    </Translate>
                  </Typography>
                </Alert>
              </Box>
            )}
          </Modal>
        )}
      </Axios>
    </div>
  )
}

export default DeleteManager
