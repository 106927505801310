import React from 'react'

import useStyles from './styles.js'

const Html = ({ children }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={{
        __html: String(children),
      }}
    />
  )
}

export default Html
