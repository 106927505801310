import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Axios from '@components/Axios';
import Card from '@components/Card';
import Modal from '@components/Modal';
import Button from '@components/Button';

import FormGroup from './components/FormGroup';

const AddEdit = (props) => {
  console.log(props.item)
  let { close, item, updateList } = props;

  const isItemNotEmpty = Object.keys(props.item).length > 0;

  const schema = {
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('REQUIRED'),
      content: Yup.string().required('REQUIRED'),
      isVisible: Yup.boolean().required('REQUIRED'),
    }),
  };

  const footer = (submit) => {
    return <Button onClick={submit}>NOTIFICATION_ADD_CARD_SEND</Button>;
  };

  const onSuccess = () => {
    close();
    updateList();
  };

  const edit = ({ handleSubmit, ...formik }) => {
    return (
      <Card footer={() => footer(handleSubmit)}>
        <FormGroup {...props} formik={formik} />
      </Card>
    );
  };

  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => close()}>
          NOTIFICATION_BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>NOTIFICATION_ADD_CARD_SEND</Button>
      </div>
    );
  };

  let params = {};
  if (item._id) {
    params = {
      method: 'put',
      others: item._id,
    };
  } else {
    params = {
      method: 'post',
    };
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values });
    resetForm();
  };

  return (
    <div className="AddEdit">
      <Axios api="notification" {...params} onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik
            {...schema}
            onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
          >
           {formik => edit(formik)}
          </Formik>
        )}
      </Axios>
    </div>
  );
};

export default AddEdit;
