import clsx from 'clsx'
import React from 'react'
import Grid from '@material-ui/core/Grid'

import Utils from '@utils'
import Icon from '@components/Icon'
import Translate from '@components/Translate'

import useStyles from './styles.js'

export default function Notice(props) {
  const classes = useStyles()
  const { className, object = {} } = props
  const { totalTrees = 0, totalFamilies = 0 } = object

  return (
    <div className={clsx([classes.root, className])}>
      <Grid className={classes.main} container spacing={3} xs={10}>
        <Grid item xs>
          <div className={classes.item}>
            <div className={classes.iconGroup}>
              <div className={classes.icon}>
                <Icon name="tree" />
              </div>
              <div className={classes.iconTitle}>
                <span>{Utils.numberFormart(totalTrees, 2)}</span>
                <Translate>ADMIN_RESULTS_NOTICE_ONE_ICON</Translate>
              </div>
            </div>
            <div className={classes.text}>
              <Translate>ADMIN_RESULTS_NOTICE_ONE_DESCRIPTION</Translate>
            </div>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.item}>
            <div className={classes.iconGroup}>
              <div className={classes.icon}>
                <Icon name="house" />
              </div>
              <div className={classes.iconTitle}>
                <span> {Utils.numberFormart(totalFamilies, 2)}</span>
                <Translate>ADMIN_RESULTS_NOTICE_TWO_ICON</Translate>
              </div>
            </div>
            <div className={classes.text}>
              <Translate>ADMIN_RESULTS_NOTICE_TWO_DESCRIPTION</Translate>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
