import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Report from './components/Report'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@components/Icon'

import Table from '@components/Table'
import Button from '@components/Button'
import Input from '@components/Input'

import './styles.scss'
import ConstructionEdit from './components/ConstructionsEdit'

const openReportInWeb = (data) => {
  window.open(`${process.env.REACT_APP_BASE_URL_CECARBON_SITE}/admin/report/${data.id}/${data.status}`);
}

const ConstructionsTableList = ({
  array = [],
  updateList,
  managers,
  enterpriseTypes
}) => {
  const [filter, setFilter] = useState({})
  const [constructionList, setConstructionList] = useState([])
  const [idReport, setIdReport] = useState()
  const [constructionToEditId, setConstructionToEditId] = useState()

  const columns = [
    { title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_NAME', field: 'name' },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_COMPANY_NAME',
      field: 'companyName'
    },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_TYPE_REGISTER',
      component: ({ typeRegister }) => {
        return typeRegister === 'Obra Teste' ? 'Não' : 'Sim'; 
      }
    },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_MANAGER',
      field: 'manager.user.name'
    },
    { title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_STATUS', field: 'stage' },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_ADDRESS',
      component: ({ address }) => {
        const { street = '', zipCode = '', state = '', city = '' } = address
        return `${street} - ${zipCode}, ${city} - ${state}`
      }
    },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_TOTAL_EMISSION',
      field: 'totalEmission'
    },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_HEADER_TOTAL_ENERGY',
      field: 'totalEnergyGJ'
    },
    {
      title: 'CLIENTS_VIEW_LIST_OPTIONS',
      className: 'options',
      component: ({ id }) => {
        return (
          <>
            <IconButton
              onClick={() => setConstructionToEditId(id)}
              className="list-edit"
            >
              <Icon size={30} name="edit" />
            </IconButton>
          </>
        )
      }
    },
    {
      title: 'CLIENTS_VIEW_LIST_TABLE_OPTIONS_ACTION',
      className: 'options',
      component: (data) => {
        return (
          <>
            <Button onClick={() => openReportInWeb(data)} variant="outlined">
              CLIENTS_VIEW_LIST_TABLE_OPTIONS_ACTION
            </Button>
          </>
        )
      }
    }
  ]

  const status = [
    {
      value: '',
      label: 'Limpa Filtro'
    },
    {
      value: 'Etapa de Projeto',
      label: 'Etapa de Projeto'
    },
    {
      value: 'Canteiro de Obras',
      label: 'Canteiro de Obras'
    },
    {
      value: 'Obra concluída',
      label: 'Obra concluída'
    }
  ]

  const onChange = ({ target: { name, value } }) => {
    console.log({
      name,
      value
    })

    const filteredArray =
      value === '' ? array : array.filter(({ stage }) => stage === value)
    filter[name] = value
    setFilter(filter)
    setConstructionList([...filteredArray])
  }

  const component = () => {
    return (
      <div>
        <Input
          type="select"
          icon="search"
          name="status"
          array={status}
          nameValue="value"
          nameText="label"
          onChange={onChange}
          autoComplete="false"
          value={filter.status}
          label="CLIENTS_TABLE_ACTION_FILTER"
        />
      </div>
    )
  }

  useEffect(() => {
    setConstructionList(array)
  }, [array])

  useEffect(() => {
    console.log({ constructionToEditId })
  }, [constructionToEditId])

  return (
    <div className="TableList">
      <Table
        data={constructionList}
        columns={columns}
        component={component}
        title="CLIENTS_VIEW_LIST_TABLE_TITLE"
      />
      <Report id={idReport} close={() => setIdReport()} />
      <ConstructionEdit
        construction={constructionList.find(
          construction => construction.id === constructionToEditId
        )}
        enterpriseTypes={enterpriseTypes}
        managers={managers}
        isOpen={!!constructionToEditId}
        onClose={() => setConstructionToEditId(null)}
        updateList={updateList}
      />
    </div>
  )
}

export default ConstructionsTableList

ConstructionsTableList.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
