import React, { useState } from 'react'

// @material-ui/icons
import Tabs from '@components/Tabs'
import Axios from '@components/Axios'

import List from './components/List'
import AddEdit from './components/AddEdit'

import './styles.scss'

const Calculator = () => {
  const [run, setRun] = useState(1)
  const [list, setList] = useState([])
  const [item, setItem] = useState({})

  const onSuccessPosts = ({ data }) => {
    setItem({})
    setList([...data])
  }

  const onError = ({ error }) => {
    console.log("error", error);
  }

  const updateList = () => {
    setRun(run + 1)
  }

  const tabs = [
    {
      icon: 'list',
      name: 'CALCULATOR_TAB_LIST',
      content: <List list={list} setItem={setItem} updateList={updateList} />
    },
    {
      icon: 'add',
      name: 'CALCULATOR_TAB_ADD',
      content: (
        <AddEdit
          item={item}
          updateList={updateList}
          close={() => setItem({})}
        />
      )
    }
  ]

  return (
    <Axios
      run={run}
      api="calculator"
      onSuccess={onSuccessPosts}
      onError={onError}
      others={{ page: 0, limit: 10 }}
    >
      <Axios
        api="calculator"
        run={item._id}
        others={item._id}
        onSuccess={setItem}
      >
        <div className="Post">
          <Tabs headerColor="primary" tabs={tabs} />
        </div>
      </Axios>
    </Axios>
  )
}

export default Calculator;
