import { whiteColor, blackGray, hexToRgb } from '@js/material.js'

const cardStyle = {
  card: {
    border: '0',
    width: '100%',
    minWidth: '0',
    display: 'flex',
    marginTop: '30px',
    fontSize: '.875rem',
    borderRadius: '6px',
    position: 'relative',
    background: whiteColor,
    flexDirection: 'column',
    wordWrap: 'break-word',
    color: 'rgba(' + hexToRgb(blackGray) + ', 0.87)',
    boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackGray) + ', 0.14)',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none'
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center'
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px'
    }
  }
}

export default cardStyle
