import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'

import Toast from '@utils/toast.utils'
import Icon from '@components/Icon'
import Axios, { http } from '@components/Axios'
import Utils from '@utils'
import Table from '@components/Table'
import Input from '@components/Input'

import './styles.scss'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Translate from '@components/Translate'
import Typography from '@components/Typography'



const TableList = () => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState({
    status: false,
    userId: null,
    name: '',
    email: ''
  })
  const [pagination, setPagination] = useState({})
  const [params, setParams] = useState({ page: 0, limit: 10 })

  const companyProfiles = [
    {
      value: '',
      label: 'Limpa Filtro'
    },
    {
      value: 'Construtora',
      label: 'Construtora'
    },
    {
      value: 'Incorporadora',
      label: 'Incorporadora'
    },
    {
      value: 'Outros',
      label: 'Outros'
    }
  ]

  const onSuccess = ({ data, ...item }) => {
    setList(data)
    setPagination(item)
  }

  const onChange = ({ target: { name, value } }) => {
    params[name] = value
    setParams({ ...params })
  }

  const component = () => {
    return (
      <div>
        <Input
          type="select"
          icon="search"
          nameText="label"
          nameValue="value"
          name="companyProfile"
          onChange={onChange}
          autoComplete="false"
          array={companyProfiles}
          value={params.companyProfile}
          label="CLIENTS_TABLE_ACTION_FILTER"
        />
      </div>
    )
  }

  const handleDeleteUser = async () => {
    try {
      if ( openModal.userId ){
        await http({
          api: 'user',
          others: `${openModal.userId}`,
          method: 'delete',
        })
        setOpenModal({
          status: false,
          userId: null,
          name: '',
          email: ''
        })
        Toast.sendMessage('success', "Usuário deletado com sucesso. ", false)
      } else {
        Toast.sendMessage('error', "Não foi possível encontrar o usuário. ", false)
      }
    } catch (error) {
      const message = Utils.getValue(error, 'response.data.message')
      if (message) {
        Toast.sendMessage('error', message, false)
      } else {
        Toast.sendMessage('error', 'AXIOS_ERRO', 10000)
      }
    }
  }

  const columns = [
    { title: 'CLIENTS_LIST_TABLE_HEADER_NAME', field: 'name' },
    { title: 'CLIENTS_LIST_TABLE_HEADER_COMPANY_NAME', field: 'companyName' },
    {
      title: 'CLIENTS_LIST_TABLE_HEADER_COMPANY_PROFILE',
      field: 'companyProfile'
    },
    { title: 'CLIENTS_LIST_TABLE_HEADER_EMAIL', field: 'email' },
    { title: 'CLIENTS_LIST_TABLE_HEADER_CITY', field: 'city' },
    { title: 'CLIENTS_LIST_TABLE_HEADER_STATE', field: 'state' },
    // {
    //   title: 'CLIENTS_LIST_TABLE_HEADER_TOTAL_EMISSION',
    //   field: 'totalEmission'
    // },
    // { title: 'CLIENTS_LIST_TABLE_HEADER_TOTAL_ENERGY', field: 'totalEnergyGJ' },
    {
      title: 'CLIENTS_LIST_OPTIONS',
      className: 'options',
      component: ({ user, ...props }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Link to={`/admin/clients-view/${user.id}`}>
              <IconButton className="list-edit">
                <Icon size={30} name="Visibility" />
              </IconButton>
            </Link>
            <IconButton className="list-edit" onClick={() => {
              setOpenModal({
                ...openModal, 
                status: true, 
                userId: user.id, 
                name: props.name, 
                email: props.email 
              })
            }}>
                <Icon size={30} name="delete_icon" />
            </IconButton>
          </div>
        )
      }
    }
  ]
  
  const Actions = ({ onSubmit }) => {
    return (
      <div className="actions">
        <Button color="secondary" onClick={() => setOpenModal({ status: false, userId: null, name: '', email: '' })}>
          BUTTON_CLOSE
        </Button>
        <Button onClick={onSubmit}>CLIENTS_OPEN_MODAL_ACTION_DELETE</Button>
      </div>
    )
  }

  return (
    <div className="TableList">
      <Axios
        run={JSON.stringify({ ...params })}
        api="clients"
        others={params}
        loading={false}
        onSuccess={onSuccess}
        onLoading={setLoading}
      >
        <Table
          data={list}
          loading={loading}
          columns={columns}
          component={component}
          title="CLIENTS_LIST_TABLE_TITLE"
          customPagination={{ search: 'name', params, setParams, pagination }}
        />
        <Modal
          open={openModal.status}
          close={() => setOpenModal({ status: false, userId: null, name: '', email: "" })}
          title="CLIENTS_OPEN_MODAL_DELETE_TITLE"
          actions={<Actions onSubmit={handleDeleteUser} />}
        >
          <Typography variant="h5" params={{ username: openModal.name, email: openModal.email}}>
              CLIENTS_OPEN_MODAL_DELETE_SUB_TITLE
          </Typography>
        </Modal>
      </Axios>
    </div>
  )
}

export default TableList

TableList.propTypes = {
  list: PropTypes.array,
  setItem: PropTypes.func,
  updateList: PropTypes.func
}
