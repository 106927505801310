import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'

import routes from '@src/routes.js'
import Navbar from '@components/Navbars/Navbar.js'
import Footer from '@components/Footer/Footer.js'
import Sidebar from '@components/Sidebar/Sidebar.js'

import image from '@images/banner/banner.png'
import logo from '@images/logo-name-white.svg'
import styles from '@js/material/layouts/adminStyle.js'

import 'perfect-scrollbar/css/perfect-scrollbar.css'

const switchRoutes = ({ array, props }) => {
  if (array && array.length) {
    return (
      array.map((prop, key) => {
        const { layout = '', params = '', path = '', routes } = prop;
        return (
          <>
            <Route
              key={key}
              path={`${layout}${path}${params}`}
              component={() => <prop.component {...props} />}
            />
            {switchRoutes({ array: routes, ...props })}
          </>
        )
      })
    )
  }
}

const useStyles = makeStyles(styles)

export default function Admin(props) {
  const classes = useStyles()

  const color = 'green'
  const mainPanel = React.createRef()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const { user } = props;
  const { permissions = [] } = user() || {}
  const array = routes.filter(item => permissions.includes(item.rule))

  return (
    <div className={classes.wrapper}>
      <Sidebar
        {...props}
        logo={logo}
        image={image}
        color={color}
        routes={array}
        open={mobileOpen}
        logoText={'Admin Blog'}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          {...props}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes({ array, props })}</div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
