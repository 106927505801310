class Util {
  isObject (value) {
    return value && value.constructor === Object
  }

  isArray (value) {
    return value && value.constructor === Array && value.length
  }

  getValue (obj, path) {
    try {
      const property = String(path)
        .replace(/\[(\w+)\]/g, '.$1')
        .replace(/^\./, '')
        .split('.')
        .reduce((acc, part) => acc && acc[part], obj)

      return property
    } catch (error) {
      return ''
    }
  }

  setValue (obj = {}, path, value) {
    let i
    let array = String(path)
      .replace(/^\./, '')
      .split('.')
    for (i = 0; i < array.length - 1; i++) {
      if (!obj[array[i]]) obj[array[i]] = {}
      obj = obj[array[i]]
    }
    obj[array[i]] = value
  }

  numberFormart (value, digits = 2) {
    return Number(value).toLocaleString('pt-BR', {
      minimumFractionDigits: digits
    })
  }

  _setParams = (others, value) => {
    if (this.isObject(others)) {
      let params = `${value}?`
      // eslint-disable-next-line
      for (const key in others) {
        if (others[key]) {
          params = `${params}${key}=${others[key]}&`
        }
      }
      return params.substring(0, params.length - 1)
    } else {
      return value
    }
  }

  getParams (url, others) {
    let params = ''
    if (others) {
      if (this.isArray(others)) {
        const value = `/${others[0]}`
        params = this._setParams(others[1], value)
      } else if (this.isObject(others)) {
        const value = ``
        params = this._setParams(others, value)
      } else {
        const value = `/${others}`
        params = this._setParams(others, value)
      }
    }
    return `${url}${params}`
  }

  removeAccents (value) {
    return String(value)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  transform (text) {
    const value = this.removeAccents(text)
    return value
      .trim()
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  elipses (input, size = 50) {
    if (String(input).length <= size) return input
    return input.substring(0, size) + '...'
  }

  SQLDateToFormDate (date) {
    if (!date) return ''

    const [year, month, day] = date.split('T')[0].split('-')

    return '01/02/2020'

    // return `${day} ${month} ${year}`

    console.log(`${day}/${month}/${year}`)

    return new Date(year, month - 1, day)
  }
}

export default new Util()
