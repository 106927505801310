import React from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Utils from '@utils'
import Input from '@components/Input'

import './styles.scss'
import Password from '../../../Password'
import AddressEditor from '../../../AddressEditor'

const FormGroupClient = props => {
  const { id } = useParams()
  const { formik } = props
  const { handleBlur, handleChange, values, errors } = formik

  console.log(values)

  return (
    <>
      <Grid item xs className="FormGroupClient">
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="creator.companyName"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_COMPANY_NAME"
            value={Utils.getValue(values, 'creator.companyName')}
            error={Utils.getValue(errors, 'creator.companyName')}
            helperText={Utils.getValue(errors, 'creator.companyName')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="creator.cpfCnpj"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_CPF_CNPJ"
            value={Utils.getValue(values, 'creator.cpfCnpj')}
            error={Utils.getValue(errors, 'creator.cpfCnpj')}
            helperText={Utils.getValue(errors, 'creator.cpfCnpj')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_MANAGER"
            value={Utils.getValue(values, 'name')}
            error={Utils.getValue(errors, 'name')}
            helperText={Utils.getValue(errors, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_EMAIL"
            value={Utils.getValue(values, 'email')}
            error={Utils.getValue(errors, 'email')}
            helperText={Utils.getValue(errors, 'email')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="creator.profession"
            onBlur={handleBlur}
            onChange={handleChange}
            label="CLIENTS_EDIT_INPUT_LABEL_PROFESSION"
            value={Utils.getValue(values, 'creator.profession')}
            error={Utils.getValue(errors, 'creator.profession')}
            helperText={Utils.getValue(errors, 'creator.profession')}
          />
        </Grid>
        <AddressEditor formik={formik} addressObjectPath="creator.address" />
        <Input
          placeholder="CLIENTS_VIEW_CARD_HEADER_INPUT_LABEL_PASSWORD"
          type="password"
          name="password"
          autoComplete="new-password"
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, 'password')}
          error={Utils.getValue(errors, 'password')}
          helperText={Utils.getValue(errors, 'password')}
          label="CLIENTS_VIEW_CARD_HEADER_INPUT_LABEL_PASSWORD"
        />
      </Grid>
    </>
  )
}

export default FormGroupClient
