import React from 'react'

import clsx from 'clsx'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import './styles.scss'

export default function MultipleSelect(props) {
  const {
    name,
    label,
    error,
    nameValue,
    value = [],
    array = [],
    helperText,
    nameText = nameValue,
  } = props

  const handleChange = ({ target: { value } }) => {
    const event = {
      target: {
        name,
        value
      }
    }
    props.handleChange(event)
  }

  const getRenderValue = select => {
    const itens = []
    // eslint-disable-next-line
    for (const id of select) {
      const item = array.find(item => item[nameValue] === id)
      if (item) {
        itens.push(item)
      }
    }
    return itens.map(item => item[nameText]).join(', ')
  }

  const getValueChecked = object => {
    return value.includes(object[nameValue])
  }

  const className = clsx('MultipleSelect', { error })

  return (
    <FormControl className={className} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        onChange={handleChange}
        renderValue={getRenderValue}
        value={Array.isArray(value) ? value : []}
        input={<OutlinedInput label={label} />}
      >
        {array.map((item, index) => (
          <MenuItem key={index} value={item[nameValue]}>
            <Checkbox checked={getValueChecked(item)} />
            <ListItemText primary={item[nameText]} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText> 
    </FormControl>

  )
}
