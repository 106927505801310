import React from 'react';
import { Grid } from '@material-ui/core';
import Utils from '@utils';
import Input from '@components/Input';
import Switch from '@material-ui/core/Switch';

const FormGroup = (props) => {
  const { formik } = props;
  const { handleBlur, handleChange, values, errors } = formik;

  return (
    <>
      <Grid item xs className="mg-auto">
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="title"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Título"
            value={Utils.getValue(values, 'title')}
            error={Utils.getValue(errors, 'title')}
            helperText={Utils.getValue(errors, 'title')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...props}
            name="content"
            type="ckeditor"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Conteúdo"
            value={Utils.getValue(values, 'content')}
            error={Utils.getValue(errors, 'content')}
            helperText={Utils.getValue(errors, 'content')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container alignItems="center">
            <Grid item>
              <label>Visível?</label>
            </Grid>
            <Grid item>
              <Switch
                name="isVisible"
                checked={!!Utils.getValue(values, 'isVisible')}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormGroup;
