import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import Menu from '@material-ui/icons/Menu'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

import Translate from '@components/Translate'
import Button from '@components/CustomButtons/Button.js'
import AdminNavbarLinks from './AdminNavbarLinks.js'

import styles from '@js/material/components/headerStyle.js'

const useStyles = makeStyles(styles)
export default function Header(props) {
  const classes = useStyles()

  const { color, routes } = props

  const appBarClasses = clsx({
    [' ' + classes[color]]: color
  })

  function makeBrand(array) {
    // eslint-disable-next-line
    for (const item of array) {
      if (window.location.pathname.includes(item.layout + item.path)) {
        return item.name
      } else if (item.routes) {
        return makeBrand(item.routes)
      }
    }
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button color="transparent" href="#" className={classes.title}>
            <Translate>{makeBrand(routes)}</Translate>
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
}
