import clsx from 'clsx'
import React from 'react'
import { format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import reports from '@images/reports.png'

import Image from '@components/Image'
import Typography from '@components/Typography'

import useStyles from './styles.js'

export default function Reports(props) {
  const classes = useStyles()
  const { title, image, className, object = {}, params } = props

  const { creator = {} } = object

  const imageHeader = () => {
    if (image) {
      return (
        <div className={classes.image}>
          <Image src={reports} />
        </div>
      )
    }
  }

  return (
    <div className={clsx([classes.root, className])}>
      {imageHeader()}
      <div  className={classes.header}>
        <div className={classes.title}>
          <Typography params={{ ...params }} variant="h3">
            {title}
          </Typography>
        </div>
        <div className={classes.description}>
          <Typography params={{ date: format(new Date(), 'dd/MM/yyyy')  }}>ADMIN_RESULTS_REPORTS_HEADER_DESCRIPTION</Typography>
        </div>
      </div>
      <Grid className={classes.container} container spacing={10} xs={10}>
        <Grid item xs>
          <Typography className={classes.reportTitle} variant="h4">
            {creator.companyName}
          </Typography>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_COMPANY</Typography>: {creator.companyName}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_CPNJ</Typography>: {creator.cpfCnpj}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_PROFILE</Typography>: {creator.companyProfile}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_COUNTRY</Typography>: {creator.companyName}
            </div>
          </div>
        </Grid>
        <Grid item xs>
          <Typography className={classes.reportTitle} variant="h4">
            {object.name}
          </Typography>
          <div className={classes.group}>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_COMPANY</Typography>:{object.name}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_CPNJ</Typography>:{creator.cpfCnpj}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_PROFILE</Typography>:{creator.companyProfile}
            </div>
            <div className={classes.groupItem}>
              <Typography variant="span">ADMIN_RESULTS_REPORTS_ITEM_COUNTRY</Typography>:{creator.companyName}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
