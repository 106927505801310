import React from 'react'
import clsx from 'clsx'

import Typography from '@components/Typography'
import useStyles from './styles.js'

export default function HeaderReports(props) {
  const classes = useStyles()
  const { className } = props
  return (
    <div className={clsx([classes.root, className])}>
      <div className={classes.title}>
        <Typography variant="h1">HEADER_RESULTS_TITLE</Typography>
      </div>
      <div className={classes.description}>
        <Typography variant="body1">HEADER_RESULTS_DESCRIPTION_ONE</Typography>
        <Typography variant="body1">HEADER_RESULTS_DESCRIPTION_TWO</Typography>
      </div>
    </div>
  )
}
