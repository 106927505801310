import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ typography, palette }) => ({
  root: {
    paddingBottom: '10rem',
  },
  main: {
    margin: '3rem 0',
  },
  container: {
    margin: 'auto',
    '& .MuiGrid-item': {
      padding: '25px 40px',
    },
  },
  title: {
    marginTop: '2rem',
    '& h3': {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginBottom: '2rem',
      fontFamily: typography.fontSemibold,
    },
  },
  item: {
    textAlign: 'center',
  },
}))
