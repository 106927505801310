import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, typography }) => ({
  root: {
    paddingBottom: '4rem',
    '@media print': {
      paddingBottom: '1rem',
    },
  },
  container: {
    margin: 'auto',
    '@media print': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  row: {
    margin: 'auto',
  },
  item: {
    padding: '1rem',
    borderRadius: 20,
    border: `solid 1px ${palette.grey[800]}`,
    '& p': {
      fontSize: '1.5rem',
      paddingBottom: 5,
      textAlign: 'center',
      color: palette.primary.main,
      fontFamily: typography.fontSemibold,
      '@media print': {
        fontSize: '1rem',
      },
    },
  },
  value: {
    fontSize: '1.8rem',
    textAlign: 'center',
    color: palette.text.primary,
    fontFamily: typography.fontSemibold,
    '@media print': {
      fontSize: '1rem',
    },
  },
}))
