import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import { InputLabel, FormHelperText } from '@material-ui/core'

import Compress from 'compressorjs'
import Cropper from 'react-cropper'

import Translate from '@components/Translate'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'
import PublishIcon from '@material-ui/icons/Publish'

import 'cropperjs/dist/cropper.css'

// Styles
import './styles.scss'

const CropperComponent = props => {
  let cropper
  const [image, setImage] = useState('')

  useEffect(() => {
    const init = async () => {
      const url = await getUrl(props.value)
      setImage(url)
    }
    if (typeof props.value === 'object') {
      init()
    }
  }, [props.value])

  const handleChange = value => {
    const { name } = props
    const event = {
      target: {
        name,
        value: value
      }
    }
    props.handleChange(event)
  }

  const cropImage = () => {
    if (image && cropper) {
      setImage('')
      cropper.getCroppedCanvas().toBlob(blob => {
        compress(blob)
      })
    }
  }

  const getUrl = file => {
    return new Promise(resolver => {
      const reader = new FileReader()
      reader.onload = function(e) {
        return resolver(e.target.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const onChangeImage = async ({ target: { files } }) => {
    const url = await getUrl(files[0])
    setImage(url)
  }

  const compressImage = blob => {
    const file = new File([blob], 'image.png', {
      type: 'image/png',
      lastModified: Date.now()
    })
    return new Promise(resolver => {
      new Compress(file, {
        quality: 0.7,
        convertSize: 1000,
        success(result) {
          resolver(result)
        }
      })
    })
  }

  const getSizeImage = file => {
    const _URL = window.URL || window.webkitURL
    return new Promise(resolver => {
      const img = new Image()
      const objectUrl = _URL.createObjectURL(file)
      img.onload = function() {
        resolver({ width: this.width, height: this.height })
      }
      img.src = objectUrl
    })
  }

  const compress = async blob => {
    const result = await compressImage(blob)
    const file = new File([result], 'file')
    const size = await getSizeImage(file)
    handleChange(result)
    if (props.setSizeImage) {
      props.setSizeImage(size)
    }
  }

  const remove = () => {
    setImage('')
    handleChange()
  }

  const renderImage = () => {
    let { value } = props
    const url = typeof value === 'object' ? image : value
    if (url) {
      return <img src={url} alt="..." />
    }
  }

  const renderCropper = () => {
    let { value, aspectRatio } = props
    if (!value) {
      if (!image) {
        return (
          <div className="content">
            <Translate>CROPPER_SELECT</Translate>
            <input onChange={onChangeImage} type="file" />{' '}
          </div>
        )
      } else {
        return (
          <Cropper
            ref={item => {
              cropper = item
            }}
            src={image}
            viewMode={1}
            guides={true}
            autoCropArea={1}
            background={false}
            // responsive={true}
            minCropBoxWidth={10}
            minCropBoxHeight={10}
            initialAspectRatio={1}
            checkOrientation={false}
            aspectRatio={aspectRatio || 'free'}
            style={{ height: 250, width: "100%" }}
          />
        )
      }
    }
  }

  const { error, label, helperText, className } = props
  const classNames = clsx('CropperComponent', className, {
    error
  })

  return (
    <div className={classNames}>
      <InputLabel>{label}</InputLabel>
      <div className="upload">
        <div className={`${error ? 'error' : ''} cropper_content`}>
          <label>
            <div className="content-file">
              {renderImage()}
              {renderCropper()}
            </div>
          </label>
        </div>
        <div className="cropper_actions">
          <IconButton onClick={cropImage}>
            <PublishIcon />
          </IconButton>
          <IconButton onClick={remove}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <FormHelperText>{helperText}</FormHelperText>
    </div>
  )
}

export default CropperComponent
